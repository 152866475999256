import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { styled } from '@linaria/react';
import React from 'react';
import Card from 'react-bootstrap/Card';
import { defaultTheme } from '../../theme/defaultTheme';
import { theme } from '../../theme/theme';
import Link from '../Link';
import RichText from '../rich_text/RichText';

interface Props {
  image: IGatsbyImageData;
  link: string;
  title: string;
  body: string;
}

const StyledImage = styled.div`
  background-color: ${defaultTheme.colors.border};
  border-radius: 20px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const StyledParagraphTruncate = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

const CardTitleLink = styled(Link)`
  color: ${theme.light.text.default};
  display: block;
  text-decoration: none;

  &:hover {
    color: ${theme.light.text.default};
    text-decoration: underline;
    cursor: pointer;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`;

const ArticleCard: React.FC<Props> = function ({ title, link, image, body }: Props) {
  return (
    <Card as="div" className="d-flex col align-content-center border-0 gap-2 position-relative">
      <StyledImage>
        <GatsbyImage image={image} objectFit="contain" className="rounded-3" alt={title} />
      </StyledImage>
      <Card.Body>
        <CardTitleLink to={link} className="typography_h5 mb-2">
          {title}
        </CardTitleLink>
        <StyledParagraphTruncate>
          <RichText className="fw-lighter typography_large" body={body} />
        </StyledParagraphTruncate>
      </Card.Body>
    </Card>
  );
};

export default ArticleCard;
