import { PageProps, graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Seo from '../../components/Seo';
import {
  getHelmetPropsFromMetatagProps,
  getJsonFromMetatagProps,
} from '../../utils/metatagHelpers';
import '@kraken-nerd/pet-food-finder/build/static/css/main.css';

interface DataProps {
  node: NodeContent;
  translations: Translations;
}

let PFF: React.LazyExoticComponent<React.FC> | null = null;

// Lazy import PFF because it isn't SSR friendly.
try {
  if (typeof window !== 'undefined') {
    PFF = React.lazy(() => import('@kraken-nerd/pet-food-finder'));
  }
} catch (e) {
  console.error('Failed to load PFF', e);
}

const PetFoodFinder: React.FC<PageProps<DataProps>> = function ({ data: { node } }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);

  useEffect(() => {
    if (typeof window?.drupalSettings?.tesla === 'object') {
      setIsLoaded(true);
    }
  }, []);

  return (
    <div>
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      {isLoaded && PFF && (
        <div id="teslaRoot" data-zindex="1201" data-isdirectlink="true">
          <PFF />
        </div>
      )}
    </div>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeComplex(id: { eq: $id }, langcode: { eq: $language }) {
      id
      path {
        alias
      }
      default_langcode
      langcode
      title
      ...Metatag
    }
    translations: allNodeComplex(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PetFoodFinder;
