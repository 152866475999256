import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import Downshift, { DownshiftProps, GetRootPropsOptions } from 'downshift';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import capitalize from 'lodash/capitalize';
import { matchSorter } from 'match-sorter';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { usePopper } from 'react-popper';
import {
  ComboboxCancel,
  ComboboxInput,
  ComboboxListGroup,
  ComboboxListGroupTitle,
  ComboboxListItem,
} from './ComboboxStyles';

export type ComboBoxItem = {
  value: string;
  category?: string;
};

export type ComboboxProps = {
  items: ComboBoxItem[];
  categories?: string[];
};

const getItems = (items: ComboBoxItem[], value: string) =>
  value ? matchSorter(items, value, { keys: ['value', 'category'] }) : items;

const Combobox: React.FC<DownshiftProps<HTMLInputElement> & ComboboxProps> = function (props) {
  const { items, categories = [] } = props;
  const [referenceRef, setReferenceRef] = useState(null);
  const [popperRef, setPopperRef] = useState(null);
  const { t } = useTranslation();
  const offsetModifier = React.useMemo(
    () => ({
      name: 'offset',
      enabled: true,
      options: {
        offset: () => {
          return [0, 10];
        },
      },
    }),
    []
  );
  const sameWidthModifier = React.useMemo(
    () => ({
      name: 'sameWidth',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }: any) => {
        // eslint-disable-next-line no-param-reassign
        state.styles.popper.width = `${state.rects.reference.width}px`;
      },
      effect: ({ state }: any) => {
        // eslint-disable-next-line no-param-reassign
        state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
      },
    }),
    []
  );

  const { styles, attributes } = usePopper(referenceRef, popperRef, {
    placement: 'bottom',
    // @ts-ignore ReactPopper doesn't have "ModifierPhases" type exposed globally.
    modifiers: [sameWidthModifier, offsetModifier],
  });

  return (
    <Downshift {...props} itemToString={item => (item ? capitalize(item.value) : '')}>
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        clearSelection,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        getRootProps,
      }) => (
        <Form.Group className="mb-3">
          <Form.Label {...getLabelProps()} className="visually-hidden">
            {t('Search Ingredients')}
          </Form.Label>
          <div
            {...getRootProps({} as GetRootPropsOptions, { suppressRefError: true })}
            className="position-relative"
          >
            <ComboboxInput
              {...getInputProps({
                placeholder: t('Search Ingredients'),
                ref: setReferenceRef,
              })}
            />
            {selectedItem ? (
              <ComboboxCancel onClick={() => clearSelection()}>
                Close <Icon path={mdiClose} size={1} />
              </ComboboxCancel>
            ) : null}
          </div>
          <div
            ref={setPopperRef}
            style={{ ...styles.popper, minHeight: '10px' }}
            {...attributes.popper}
            className="sticky-top"
          >
            <ComboboxListGroup {...getMenuProps({})}>
              {isOpen && categories.length === 0
                ? getItems(items, inputValue || '').map((item, index: number) => (
                    <ComboboxListItem
                      {...getItemProps({
                        key: item.value,
                        index,
                        item,
                        style: {
                          backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                          fontWeight: selectedItem === item ? 'bold' : 'normal',
                        },
                      })}
                    >
                      {item.value}
                    </ComboboxListItem>
                  ))
                : null}
              {isOpen && categories.length > 0
                ? categories.map(category => {
                    const sortedItems = getItems(items, inputValue || '').sort(
                      (a, b) => categories.indexOf(a.category) - categories.indexOf(b.category)
                    );
                    const categoryItems = sortedItems.filter(
                      (item: ComboBoxItem) => item.category === category
                    );
                    if (categoryItems.length) {
                      return (
                        <ul
                          key={`cat-${category}`}
                          role="group"
                          aria-labelledby={`cat-${category}`}
                        >
                          <ComboboxListGroupTitle id={`cat-${category}`} role="presentation">
                            {category}
                          </ComboboxListGroupTitle>
                          {categoryItems.map(item => (
                            <ComboboxListItem
                              {...getItemProps({
                                key: item.value,
                                index: sortedItems.indexOf(item),
                                item,
                                style: {
                                  backgroundColor:
                                    highlightedIndex === sortedItems.indexOf(item)
                                      ? '#2553F8'
                                      : undefined,
                                  color:
                                    highlightedIndex === sortedItems.indexOf(item)
                                      ? '#FFF'
                                      : undefined,
                                  fontWeight: selectedItem === item ? 'bold' : undefined,
                                },
                              })}
                            >
                              {item.value}
                            </ComboboxListItem>
                          ))}
                        </ul>
                      );
                    }
                    return null;
                  })
                : null}
            </ComboboxListGroup>
          </div>
        </Form.Group>
      )}
    </Downshift>
  );
};

export default Combobox;
