import { GatsbyImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { styled } from '@linaria/react';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Link from '../Link';
import IngredientMap from './IngredientMap';

interface IngredientProps {
  ingredient: TaxonomyTermIngredient;
  show: boolean;
  handleClose: () => void;
}

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  max-width: 100%;

  > * {
    flex-grow: 1;
  }

  li.nav-item {
    margin-right: 0;
    padding-right: 0.375rem;
    padding-bottom: 0.375rem;
  }

  button {
    width: 100%;
  }
`;

const IngredientModal: React.FC<IngredientProps> = function ({
  ingredient,
  show = false,
  handleClose,
}) {
  const { t } = useTranslation();
  const image = ingredient.relationships?.field_image?.gatsbyImage;
  const benefits = ingredient.relationships?.field_benefits;
  const locations = ingredient.relationships?.field_locations;

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered fullscreen="sm-down">
      <AnalyticsPoint type="module" typeLabel="ingredient_modal" label="ingredient modal">
        <Modal.Header closeButton>
          <div className="h6 m-0">{ingredient.name}</div>
        </Modal.Header>
        <Modal.Body className="p-3 p-md-4">
          <StyledTabs
            onSelect={key =>
              key === 'map' &&
              sendIt({
                event: 'select_content',
                eventCategory: 'ingredients',
                eventAction: 'ingredient source map',
                eventLabel: `ingredient - ${ingredient.name}`,
                eventParams: {
                  cta_location: 'nutrition tab',
                  content_type: 'ingredient source map',
                  item_id: ingredient.name,
                },
              })
            }
            defaultActiveKey="details"
            mountOnEnter
          >
            <Tab title={t('Ingredient Details')} eventKey="details" tabClassName="text-uppercase">
              {ingredient.description && (
                <Row className="py-3">
                  <Col dangerouslySetInnerHTML={{ __html: ingredient.description?.processed }} />
                </Row>
              )}
              {image && (
                <Row>
                  <Col xs={5} sm={6} className="ps-0">
                    <GatsbyImage alt={ingredient.name} image={image} objectFit="contain" />
                  </Col>
                  {benefits && benefits.length >= 1 && (
                    <Col>
                      <h3 className="h6 mb-3">Benefits</h3>
                      {benefits.map(benefit => {
                        const benefitIcon =
                          benefit.relationships?.icon?.relationships?.svg?.url;
                        return (
                          <Row key={benefit.id} className="my-2 align-items-center">
                            {benefitIcon && (
                              <Col xs="auto" className="pe-0">
                                <img
                                  src={benefitIcon}
                                  alt={ingredient.name}
                                  style={{ height: '24px', width: '24px' }}
                                />
                              </Col>
                            )}
                            <Col>{benefit.name}</Col>
                          </Row>
                        );
                      })}
                    </Col>
                  )}
                </Row>
              )}
            </Tab>
            {locations && locations.length >= 1 && (
              <Tab title={t('Source Map')} eventKey="map" tabClassName="text-uppercase">
                <IngredientMap style={{ marginTop: '-0.375rem' }} locations={locations} />
                <div className="py-3">
                  <span className="typography_h5">
                    {t('View more ingredients on our')}&nbsp;
                    <Link to="/nutrition/trace-your-pets-food">
                      {t('Pet Food Ingredients Source Map.')}
                    </Link>
                  </span>

                  <p className="mt-2">{t('Last updated January 2023.')}</p>
                  <p>
                    {t(
                      'The nature of the supply chain is dynamic and evolves regularly. We will continually strive to provide the most current information possible. This will vary ingredient to ingredient, as the supply chain for each ingredient is unique. Although the locations on the map represent the majority of our ingredient sourcing, it is possible that certain ingredients are sourced from additional locations as well.'
                    )}
                  </p>
                </div>
              </Tab>
            )}
          </StyledTabs>
        </Modal.Body>
      </AnalyticsPoint>
    </Modal>
  );
};

export default IngredientModal;
