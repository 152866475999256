export const validateRequired = (label: string) => {
  const labelText = label !== undefined ? label : 'This';
  return `${labelText} field is required.`;
};

export const validateMaxLength = (label: string, length: number) => {
  return {
    value: length,
    message: `${label} field is limited to ${length} characters.`,
  };
};

export const validateDropdownRequired = (
  userInput: string | undefined,
  unchangedOption: string,
  label: string,
) => {
  if (userInput === unchangedOption) {
    return `${label} field is required.`;
  }
  return true;
};

export const validateMinLength = (label: string, length: number) => {
  return {
    value: length,
    message: `${label} field must be at least ${length} characters.`,
  };
};

export const validateEmailPattern = () => {
  return {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: 'A valid E-mail address is required.',
  };
};

export const validateTextPattern = (label: string) => {
  return {
    value: /^[a-zA-Z0-9_-]+\s?([a-zA-Z0-9_-]+\s?){0,3}$/,
    message: `${label} field must not contain numbers or special characters.`,
  };
};
export const validateTextWithNumberPattern = (label: string) => {
  return {
    value: /^[a-zA-Z0-9]+$/,
    message: `${label} field must not contain special characters.`,
  };
};

export const validateWordLimit = (label: string) => {
  return {
    value: /^(\S+(\s+|$)){0,200}$/,
    message: `${label} field must be 200 words or less.`,
  };
};

export const validatePostalCodePattern = (label: string) => {
  return {
    value: /(\d{6})/,
    message: `${label} must be 6 digits.`,
  };
};

export const validateBirthDayPattern = (label: string) => {
  return {
    value: /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
    message: `${label} must be in the format mm/dd/yyyy`,
  };
};

export const getCanadianProvinces = () => {
  return {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NS: 'Nova Scotia',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
    YT: 'Yukon',
  };
};
export const formatPhoneNumber = (input: string): string => {
  // Remove all non-numeric characters
  let cleaned = `${input}`.replace(/\D/g, '');

  if (cleaned.length > 10) {
    cleaned = cleaned.slice(0, 10);
  }
  if (cleaned.length > 3) {
    cleaned = `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
  }
  if (cleaned.length > 7) {
    cleaned = `${cleaned.slice(0, 7)}-${cleaned.slice(7)}`;
  }

  return cleaned;
};

export const getRecaptchaToken = async (): Promise<string> => {
  const tokenPromise: Promise<string> = new Promise((res, rej) => {
    window.grecaptcha.ready(async () => {
      const token = await window.grecaptcha.execute(process.env.GATSBY_RECAPTCHA_SITE_KEY, {
        action: 'submit',
      });

      if (token) {
        res(token);
      } else {
        rej(Error('grecaptcha rejected'));
      }
    });
  });

  return tokenPromise;
};
