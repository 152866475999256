import React, { ReactNode } from 'react';
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react';
import { StyledCarousel, ArrowContainer, DotsContainer } from './Carousel.styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CustomArrow = (props: any) => {
  const { className, onClick, type, defaultArrowsAndDots } = props;
  return (
    <ArrowContainer
      default={defaultArrowsAndDots}
      className={`${className} ${type}-arrow`}
      onClick={onClick}
    >
      <Icon
        path={type === 'prev' ? mdiArrowLeft : mdiArrowRight}
        size="20"
        style={{ width: '100%', height: '100%' }}
        color="black"
        aria-hidden
      />
    </ArrowContainer>
  );
};

const Carousel = ({
  children,
  customSettings,
  defaultArrowsAndDots,
}: {
  children: ReactNode;
  customSettings: object;
  defaultArrowsAndDots?: boolean;
}) => {
  const settings: any = {
    ...customSettings,
    nextArrow: <CustomArrow type="next" defaultArrowsAndDots={defaultArrowsAndDots} />,
    prevArrow: <CustomArrow type="prev" defaultArrowsAndDots={defaultArrowsAndDots} />,
  };

  if (!defaultArrowsAndDots) {
    settings.appendDots = (dots: any) => (
      <DotsContainer>
        <ul> {dots} </ul>
      </DotsContainer>
    );
  }

  return <StyledCarousel {...settings}>{children}</StyledCarousel>;
};

export default Carousel;
