import { graphql, useStaticQuery } from 'gatsby';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { styled } from '@linaria/react';
import * as React from 'react';
import Container from 'react-bootstrap/Container';
import { getParagraph } from '../utils/paragraphHelpers';
import { defaultColors } from '../theme/colors';

const StyledFooter = styled.footer<Omit<FooterProps, 'data'>>`
  background-color: ${defaultColors.dark};
  color: #fff;

  /* Padding bottom is to reserve whitespace for sticky features. */
  padding: 0 0 40px;

  ul.menu {
    flex-wrap: wrap;

    a {
      color: #ffffff;
    }
  }
`;

interface FooterProps {
  theme: string;
  language: Languages;
}

interface DataProps {
  footer: {
    langcode: Languages;
    relationships: {
      rows: [Paragraph];
    };
  };
}

const Footer: React.FC<FooterProps> = function ({ language, theme }) {
  const data = useStaticQuery<DataProps>(graphql`
    {
      allStorageFooter {
        edges {
          node {
            langcode
            relationships {
              rows: field_rows {
                type: __typename
                ...ParagraphFooterRow
                ...ParagraphHorizontalRule
              }
            }
          }
        }
      }
    }
  `);
  const footer = data.allStorageFooter.edges.filter(({ node }) => node.langcode === language)[0];
  const rows = footer?.node?.relationships?.rows;
  return (
    <AnalyticsPoint
      type="module"
      typeLabel="footer"
      label="footer"
      as={StyledFooter}
      role="contentinfo"
      theme={theme}
    >
      <Container fluid>
        <Container fluid="lg">
          {rows &&
            rows.map(row => {
              return (
                <React.Fragment key={row.id}>
                  {getParagraph({ node: row, props: { location: 'footer' } })}
                </React.Fragment>
              );
            })}
        </Container>
      </Container>
    </AnalyticsPoint>
  );
};

export default Footer;
