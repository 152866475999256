import { useLocation } from '@reach/router';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { navigate } from 'gatsby';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { styled } from '@linaria/react';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import SearchPageService, { FacetCountValue, PageType, PageTypeLabels } from './SearchPageService';

interface Facets {
  facets: ContentTypeCategory[][];
}

interface ContentTypeCategory {
  content_type_category: ContentTypeCategoryItem[];
}

interface ContentTypeCategoryItem {
  url: string;
  raw_value: string;
  values: FacetCountValue;
}

const StyledTabs = styled(Tabs)`
  background: #f8faff;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;

  .nav-item .nav-link {
    padding: 10px 30px;
    min-width: 175px;
    background-color: #edf0f8;
    border-bottom: none;
    color: #2b2b2b;
  }

  .nav-item .nav-link.active {
    border-bottom: none;
    color: #000;
    background: #fff;
  }

  &::before {
    display: none;
  }

  overflow: auto hidden;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;

  @media (hover: none) {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  @media (min-width: 780px) {
    justify-content: center;
  }
`;

interface Props {
  children: React.ReactNode;
  language: string;
}

const apiEndpoint = process.env.GATSBY_DRUPAL_ENDPOINT || '';
const apiKey = process.env.GATSBY_DRUPAL_API_KEY || '';

type CountProps = Record<PageType, FacetCountValue['count']>;
async function getDrupalCounts(location: string, language: string) {
  const queryParams = new URLSearchParams(location);
  const queryObject = Object.fromEntries(queryParams);
  const params = {
    language,
    keywords: queryObject.keywords || '',
  };
  const langPrefix = language === 'en' ? '' : `${language}/`;

  const source = axios.CancelToken.source();
  const response = await axios.get<DrupalSearch & Facets>(`${apiEndpoint}${langPrefix}api/search`, {
    params,
    cancelToken: source.token,
    headers: {
      'api-key': apiKey,
    },
  });

  const FacetCountValues =
    response?.data?.facets[0][0].content_type_category?.map(item => item.values) || [];
  const mapResult = new Map([
    [PageType.Products, 0],
    [PageType.Articles, 0],
    [PageType.Pages, 0],
    [PageType.Breeds, 0],
  ]);

  if (FacetCountValues.length > 0) {
    FacetCountValues.forEach(item => {
      const pageType = SearchPageService.getSearchPageTypeFromFacet(item.value);
      if (pageType) mapResult.set(pageType, item.count);
    });
  }

  return Object.fromEntries(mapResult) as CountProps;
}

const SearchTabs: React.FC<Props> = ({ children, language }) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;
  const { data = { products: 0, articles: 0, pages: 0, breeds: 0 } } = useQuery({
    queryKey: ['getDrupalResultsCount', location.search, language],
    queryFn: () => getDrupalCounts(location.search, language),
    onError: error => {
      console.error(error);
    },
  });
  const searchPages = SearchPageService.useGetSearchPages(activeLanguage);
  const activePage = SearchPageService.useGetActiveSearchPage(activeLanguage);
  const [activeTab] = useState(activePage.api_slug);

  const onTabSelect = (tab: string) => {
    if (tab === activeTab) return;
    const queryParams = new URLSearchParams(location.search);

    const keywordsParam = queryParams.has('keywords')
      ? `?keywords=${queryParams.get('keywords')}`
      : '';

    const analyticsData = {
      event: `${tab}_tab_select`,
      eventCategory: `${tab} tab select`,
      eventAction: tab,
      eventLabel: '(not set)',
      eventParams: {
        search_tab_category: tab,
      },
    };
    sendIt(analyticsData);

    const newPage = searchPages.filter(page => {
      return page.node.api_slug === tab;
    })[0].node;

    navigate(`${newPage.path.alias}${keywordsParam}`);
  };

  return (
    <StyledTabs mountOnEnter onSelect={(tab: string) => onTabSelect(tab)} activeKey={activeTab}>
      {searchPages.map(page => {
        const searchPageType = page.node.api_slug as PageType;
        return (
          <Tab
            key={searchPageType}
            eventKey={searchPageType}
            title={
              <span>
                {/* i18next-extract-mark-context-next-line ["Products", "Articles", "Breeds", "Pages"] */}
                {t('PageTypeLabels', { context: PageTypeLabels[page.node.api_slug] })} (
                {data[searchPageType]}) <span className="visually-hidden">Results</span>
              </span>
            }
          >
            <AnalyticsPoint
              type="module"
              typeLabel={`search_tab_${page.node.api_slug}`}
              label={page.node.title}
            >
              {children}
            </AnalyticsPoint>
          </Tab>
        );
      })}
    </StyledTabs>
  );
};
export default SearchTabs;
