/* eslint-disable newline-per-chained-call */
import * as Yup from 'yup';
import { TranslateFunctionType } from '../../../utils/TranslateFunctionType';

export const contactReasonAnswers = {
  QUESTION: 'Question',
  COMPLAINT: 'Complaint',
  COMMENT: 'Comment',
} as const;

export const textPattern = /^[a-zA-Z_-]+\s?([a-zA-Z_-]+\s?){0,3}$/;
export const upcPattern = /^(\d{6}|\d{10})$/;

type Keys = keyof typeof contactReasonAnswers;

type ContactReasonAnswersValues = (typeof contactReasonAnswers)[Keys];

export const moreSpecificReasonAnswers: Record<
  ContactReasonAnswersValues,
  Record<string, { label: string; value: string }>
> = {
  [contactReasonAnswers.QUESTION]: {
    PRODUCT_PACKAGING: { label: 'Product & Packaging', value: 'product' },
    WHERE_TO_BUY: { label: 'Where to Buy', value: 'wtb' },
    PROMOTIONS: { label: 'Promotions & Special Offers', value: 'promotions' },
    VETERINARY: { label: 'Veterinary Product or from a Veterinary Clinic', value: 'veterinary' },
    GENERAL_QUESTION: { label: 'General Questions', value: 'general_question' },
  },
  [contactReasonAnswers.COMPLAINT]: {
    PRODUCT_PACKAGING: { label: 'Product & Packaging', value: 'product' },
    PRODUCT_AVAILABILITY: { label: 'Product Availability', value: 'product_availability' },
    PROMOTIONS: { label: 'Promotions & Special Offers', value: 'promotions' },
    VETERINARY: { label: 'Veterinary Product or from a Veterinary Clinic', value: 'veterinary' },
    GENERAL_COMMENT: { label: 'General Comment', value: 'general_comment' },
  },
  [contactReasonAnswers.COMMENT]: {
    GENERAL_COMMENT: { label: 'General Comment', value: 'general_comment' },
  },
};

export const contactUsSchema = (t: TranslateFunctionType) =>
  Yup.object({
    contactReason: Yup.string().required(t('Please fill out a Contact Reason.')),
    moreSpecificReason: Yup.string().required(t('Please fill out a Specific Reason.')),
    message: Yup.string()
      .max(5000, t('Max number of characters is 5000.'))
      .required(t('Please fill out a message.')),
    specificPurchaseReason: Yup.number().when('contactReason', {
      is: contactReasonAnswers.COMPLAINT,
      then: Yup.number()
        .transform(value => value ?? null)
        .nullable(),
    }),
    upcCode: Yup.string()
      .transform((o, c) => (o === '' ? '' : c))
      .matches(/^[0-9]*$/, t('UPC Code must be numeric'))
      .min(6, t('Minimum number is 6 characters.'))
      .max(10, t('Maximum number is 10 characters.'))
      .test('len', t('Invalid length'), val => {
        const len = val?.length || 0;
        return len !== 7 && len !== 8 && len !== 9;
      })
      .nullable(),

    manufactureCode: Yup.string(),

    firstName: Yup.string().nullable().required(t('Please fill out a First Name.')),

    lastName: Yup.string().nullable().required(t('Please fill out a Last Name.')),

    city: Yup.string().nullable(),

    province: Yup.string().required(t('Please select a province.')),

    postal_code: Yup.string()
      .matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, t('Please provide a valid postal code'))
      .min(7, t('Provide a 6 digit postal code.'))
      .max(7, t('Provide a 6 digit postal code.'))
      .transform((o, c) => (o === '' ? null : c))
      .nullable()
      .required(t('Please enter a postal code')),

    // eslint-disable-next-line newline-per-chained-call
    email: Yup.string()
      .email(t('Provide a valid email address.'))
      .required(t('Please fill out an Email.')),
    address1: Yup.string(),
    address2: Yup.string(),
    phone_number: Yup.string().nullable(),
    ownedDogs: Yup.number().transform((value: number) => value || 0),
    ownedCats: Yup.number().transform((value: number) => value || 0),
    emailSubscribingConsent: Yup.boolean(),
  }).required();

export type ContactUsInputsType = Yup.InferType<ReturnType<typeof contactUsSchema>>;

const encodeBase64File = (file: string) => {
  let encoded = file.replace(/^data:(.*,)?/, '');
  if (encoded.length % 4 > 0) {
    encoded += '='.repeat(4 - ((encoded?.length || 0) % 4));
  }
  return encoded;
};

export const getBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(encodeBase64File(reader.result?.toString() ?? '') || '');
    };
    reader.onerror = error => reject(error);
  });
};
