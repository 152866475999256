import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { styled } from '@linaria/react';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Container, ContainerProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Link from 'src/components/Link';
import { CMS_THEME } from '../common/enums';
import { theme } from '../theme/theme';

// ported over from purina.com to be used as a static/non-cms component in the breed detail page PC-241

interface BannerDismissibleProps {
  node: IStorageBanner;
  behaviors?: {
    entity_variant?: {
      theme: CMS_THEME;
    };
    entity_buttons?: {
      dismiss_button: boolean;
      dismiss_duration: number;
    };
  };
}
const DefaultSettings = {
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
  entity_buttons: {
    dismiss_duration: 3,
    dismiss_button: true,
  },
};

const DismissButton = styled.button`
  position: absolute;
  border: none;
  top: 0;
  right: 0;
  background-color: transparent;
`;
const Wrapper = styled.div<ContainerProps & { cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  display: flex;
  position: relative;
`;
const BannerContainer = styled.div`
  margin: auto;
  padding-left: ${theme.common.spacing[5]};
  padding-right: ${theme.common.spacing[5]};
  text-align: center;

  @media (min-width: ${theme.media.lg}) {
    padding-left: ${theme.common.spacing[9]};
    padding-right: ${theme.common.spacing[9]};
  }
`;

const BannerDismissible: React.FC<BannerDismissibleProps> = ({ node: banner }) => {
  const { t } = useTranslation();
  const [showBanner, setShowBanner] = useState(true);
  const storageKey = 'dismissTime';
  const settings = Object.assign(DefaultSettings, banner?.behaviors || {});
  const expireInSeconds = Number(settings.entity_buttons.dismiss_duration * 60);
  const showDismissBtn = Boolean(settings.entity_buttons.dismiss_button);
  const cmsTheme = settings.entity_variant.theme;

  useEffect(() => {
    const dismissedAt = Number(localStorage.getItem(storageKey));
    const currentTime = Number(new Date().getTime());
    const shouldShowBanner = !dismissedAt || currentTime - dismissedAt >= expireInSeconds * 1000;
    setShowBanner(shouldShowBanner);
  }, [expireInSeconds]);

  const handleDismiss = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowBanner(false);
    localStorage.setItem(storageKey, String(new Date().getTime()));
  };
  return showBanner ? (
    <Wrapper
      id={banner.component_id}
      fluid
      data-id={banner.drupal_id}
      className="py-3"
      cmsTheme={cmsTheme}
    >
      <Container className="position-relative">
        <BannerContainer>
          {banner?.title ? (
            <span className="d-block d-lg-inline me-lg-3 fs-4 mb-0 fw-normal">{banner.title}</span>
          ) : null}
          {banner?.link ? (
            <Link
              className={`d-inline-block fs-4 fw-bold ${
                cmsTheme === 'dark' ? 'link-light' : 'link-dark'
              }`}
              to={banner.link.url}
              aria-describedby={banner.title}
            >
              {banner.link.title ? banner.link.title : null}
            </Link>
          ) : null}
        </BannerContainer>

        {showDismissBtn && (
          <DismissButton type="button" aria-label={t('Dismiss banner')} onClick={handleDismiss}>
            <Icon
              path={mdiClose}
              size={1}
              color={cmsTheme === 'dark' ? theme.common.gray[300] : theme.common.gray[500]}
            />
          </DismissButton>
        )}
      </Container>
    </Wrapper>
  ) : null;
};

export default BannerDismissible;
