import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import {
  validateEmailPattern,
  validateRequired,
  validateTextPattern,
} from '../../../utils/formHelpers';
import { IProClubMemberFormData, ProClubMemberFormProps } from './types';

const ProClubMemberForm = ({ getFieldDataByName }: ProClubMemberFormProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IProClubMemberFormData>();

  return (
    <>
      <Row className="gx-3 gy-3">
        <Col>
          <h5>{getFieldDataByName('step_1')?.text}</h5>
        </Col>
      </Row>
      <Row className="gx-3 gy-3">
        <Col md={6}>
          <Form.Group controlId="firstName" className="required">
            <Form.Label>{getFieldDataByName('first_name')?.title}</Form.Label>
            <Form.Control
              {...register('firstName', {
                required: validateRequired(getFieldDataByName('first_name')?.title),
              })}
              type="text"
              isInvalid={!!errors?.firstName}
              aria-invalid={!!errors?.firstName}
              placeholder={getFieldDataByName('first_name')?.title}
              aria-required="true"
            />
            {errors?.firstName && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.firstName.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="lastName" className="required">
            <Form.Label>{getFieldDataByName('last_name')?.title}</Form.Label>
            <Form.Control
              {...register('lastName', {
                required: validateRequired(getFieldDataByName('last_name')?.title),
              })}
              type="text"
              isInvalid={!!errors?.lastName}
              aria-invalid={!!errors?.lastName}
              placeholder={getFieldDataByName('last_name')?.title}
              maxLength={60}
              aria-required="true"
            />
            {errors?.lastName && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.lastName.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="address" className="required">
            <Form.Label>{getFieldDataByName('address')?.title}</Form.Label>
            <Form.Control
              {...register('address', {
                required: validateRequired(getFieldDataByName('address')?.title),
              })}
              type="text"
              isInvalid={!!errors?.address}
              aria-invalid={!!errors?.address}
              placeholder={getFieldDataByName('address')?.title}
              maxLength={60}
              aria-required="true"
            />
            {errors?.address && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.address.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="city" className="required">
            <Form.Label>{getFieldDataByName('city')?.title}</Form.Label>
            <Form.Control
              {...register('city', {
                required: validateRequired(getFieldDataByName('city')?.title),
              })}
              type="text"
              isInvalid={!!errors?.city}
              aria-invalid={!!errors?.city}
              placeholder={getFieldDataByName('city')?.title}
              maxLength={60}
              aria-required="true"
            />
            {errors?.city && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.city.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="province" className="required">
            <Form.Label>{getFieldDataByName('province')?.title}</Form.Label>
            <Form.Control
              {...register('province', {
                required: validateRequired(getFieldDataByName('province')?.title),
                pattern: validateTextPattern(getFieldDataByName('province')?.title),
              })}
              type="text"
              isInvalid={!!errors?.province}
              aria-invalid={!!errors?.province}
              placeholder={getFieldDataByName('province')?.title}
              maxLength={60}
              aria-required="true"
            />
            {errors?.province && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.province.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="postalCode" className="required">
            <Form.Label>{getFieldDataByName('postal_code')?.title}</Form.Label>
            <Form.Control
              {...register('postalCode', {
                required: validateRequired(getFieldDataByName('postal_code')?.title),
              })}
              type="text"
              isInvalid={!!errors?.postalCode}
              aria-invalid={!!errors?.postalCode}
              placeholder={getFieldDataByName('postal_code')?.title}
              maxLength={60}
              aria-required="true"
            />
            {errors?.postalCode && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.postalCode.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="homePhoneNumber" className="required">
            <Form.Label>{getFieldDataByName('home_telephone')?.title}</Form.Label>
            <Form.Control
              {...register('homePhoneNumber', {
                required: validateRequired(getFieldDataByName('home_telephone')?.title),
              })}
              type="text"
              isInvalid={!!errors?.homePhoneNumber}
              aria-invalid={!!errors?.homePhoneNumber}
              placeholder={getFieldDataByName('home_telephone')?.title}
              aria-required="true"
            />
            {errors?.homePhoneNumber && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.homePhoneNumber.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="mobilePhoneNumber" className="required">
            <Form.Label>{getFieldDataByName('mobile_telephone')?.title}</Form.Label>
            <Form.Control
              {...register('mobilePhoneNumber', {
                required: validateRequired(getFieldDataByName('mobile_telephone')?.title),
              })}
              type="text"
              isInvalid={!!errors?.mobilePhoneNumber}
              aria-invalid={!!errors?.mobilePhoneNumber}
              placeholder={getFieldDataByName('mobile_telephone')?.title}
              aria-required="true"
            />
            {errors?.mobilePhoneNumber && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.mobilePhoneNumber.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="email" className="required">
            <Form.Label>{getFieldDataByName('email_address')?.title}</Form.Label>
            <Form.Control
              {...register('email', {
                required: validateRequired(getFieldDataByName('email_address')?.title),
                pattern: validateEmailPattern(),
              })}
              type="text"
              isInvalid={!!errors?.email}
              aria-invalid={!!errors?.email}
              placeholder={getFieldDataByName('email_address')?.title}
              maxLength={60}
              aria-required="true"
            />
            {errors?.email && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.email.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default ProClubMemberForm;
