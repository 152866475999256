import { css } from '@linaria/core';
import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RichText from '../../../rich_text/RichText';
// eslint-disable-next-line import/no-cycle
import { IContactUsFormData } from '../../ContactUsForm';
import { contactReasonAnswers, moreSpecificReasonAnswers } from '../../utils/contactUs';
// eslint-disable-next-line import/no-cycle
import InputField, { requiredClass } from '../InputField';

const tooltipClass = css`
  min-width: 320px;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
`;

interface IContactReasonFormProps {
  getFieldDataByName: Function;
}

const ContactReason: React.FC<IContactReasonFormProps> = ({ getFieldDataByName }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<IContactUsFormData>();
  const { t } = useTranslation();

  const contactReason = watch('contactReason') as
    | (typeof contactReasonAnswers)['COMMENT']
    | (typeof contactReasonAnswers)['COMPLAINT']
    | (typeof contactReasonAnswers)['QUESTION'];

  const specificReason = watch('moreSpecificReason');
  // conditions for conditionally rendering 3 fields about upc code
  const showSpecificQuestionsForComplaint =
    contactReason === contactReasonAnswers.COMPLAINT &&
    specificReason === moreSpecificReasonAnswers?.Complaint?.PRODUCT_PACKAGING?.value;

  // when complaint is selected and either product packaging or e-commerce is selected every field that can be required is required
  // const requiredUPCCode = (watch('specificPurchaseReason') as unknown as string) === '1';

  const specificReasonOptions = moreSpecificReasonAnswers[contactReason];

  return (
    <>
      <Col xs={12} md={6}>
        <Form.Group controlId="contactReason">
          <Form.Label className={requiredClass}>
            {getFieldDataByName('select_a_contact_reason')?.title}
          </Form.Label>
          <Form.Select
            {...register('contactReason')}
            placeholder="select"
            aria-label="Select a contact reason"
            isInvalid={!!errors.contactReason}
            aria-invalid={!!errors.contactReason}
          >
            <option key="cr-default" value="">
              {t('Select')}
            </option>
            {getFieldDataByName('select_a_contact_reason').options.map((opt: any) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </Form.Select>
          {errors.moreSpecificReason && (
            <Form.Control.Feedback type="invalid">
              {errors.moreSpecificReason?.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <Form.Group controlId="moreSpecificReason">
          <Form.Label className={requiredClass}>{t('More Specifically About')}</Form.Label>
          <Form.Select
            {...register('moreSpecificReason')}
            isInvalid={!!errors.moreSpecificReason}
            aria-invalid={!!errors.moreSpecificReason}
            aria-label="Select a reason"
          >
            <option key="sr-default" value="">
              {t('Select')}
            </option>
            {contactReason &&
              Object.entries(specificReasonOptions).map(([key, { label, value }]) => (
                <option key={key} value={value}>
                  {/* i18next-extract-mark-context-next-line ["Product & Packaging", "Where to Buy", "Donations", "Promotions & Special Offers", "General Questions", "Product Availability", "Ecommerce (Just Right Purina Store Only, Vet Direct, P4P)", "General Comment"] */}
                  {t('specificReasonOptionsLabels', { context: label })}
                </option>
              ))}
          </Form.Select>
          {errors.moreSpecificReason && (
            <Form.Control.Feedback type="invalid">
              {errors.moreSpecificReason?.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col xs={12}>
        <InputField
          register={register}
          name="message"
          as="textarea"
          className="col-lg-12"
          placeholder={getFieldDataByName('enter_your_message')?.title}
          label={getFieldDataByName('enter_your_message')?.title}
          errors={errors}
          required
        />
      </Col>
      {showSpecificQuestionsForComplaint && (
        <>
          <Col md={6} xs={12}>
            <InputField
              errors={errors}
              register={register}
              name="upcCode"
              label={t('UPC Code')}
              maxLength="10"
              required={showSpecificQuestionsForComplaint}
              tooltip={
                getFieldDataByName('upc_code_tooltip')?.text ? (
                  <RichText
                    className={`${tooltipClass} p-3`}
                    body={getFieldDataByName('upc_code_tooltip')?.text}
                  />
                ) : undefined
              }
            />
            <Form.Text className="text-muted d-flex">
              {t('Please enter a valid 6- or 10-digit UPC (no characters or spaces)')}.
            </Form.Text>
          </Col>
          <Col md={6} xs={12}>
            <InputField
              errors={errors}
              register={register}
              name="manufactureCode"
              label={t('Manufacturer Code')}
              tooltip={
                getFieldDataByName('manufacturer_code_tooltip')?.text ? (
                  <RichText
                    className={`p-3 ${tooltipClass}`}
                    body={getFieldDataByName('manufacturer_code_tooltip')?.text}
                  />
                ) : undefined
              }
            />
          </Col>
        </>
      )}
      <Col xs={12}>
        <hr className="mt-3" />
      </Col>
    </>
  );
};

export default ContactReason;
