// TODO: fix hardcoded data for select items: pet type and cat_or_dog
//  ******************************************************************
/* eslint-disable max-lines */
/* eslint-disable complexity */
import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Alert from 'src/components/alert/Alert';
import { nominatePetHeroPost } from '../../../utils/clientRequests';
import {
  formatPhoneNumber,
  validateEmailPattern,
  validateRequired,
  validateTextPattern,
  validateWordLimit,
} from '../../../utils/formHelpers';
import RichText from '../../rich_text/RichText';
import { FormValues, NominatePetHeroFormProps } from './types';

const useCanadianProvinces = () => {
  const { t } = useTranslation();
  return {
    AB: t('Alberta'),
    BC: t('British Columbia'),
    MB: t('Manitoba'),
    NB: t('New Brunswick'),
    NL: t('Newfoundland and Labrador'),
    NS: t('Nova Scotia'),
    ON: t('Ontario'),
    PE: t('Prince Edward Island'),
    QC: t('Quebec'),
    SK: t('Saskatchewan'),
    NT: t('Northwest Territories'),
    NU: t('Nunavut'),
    YT: t('Yukon'),
  };
};

const NominatePetHeroForm = ({ getFieldDataByName }: NominatePetHeroFormProps) => {
  const { t } = useTranslation();
  const provinces = Object.entries(useCanadianProvinces());
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    watch,
    // control,
  } = useForm<FormValues>({
    defaultValues: {
      first_name: '',
      last_name: '',
      city: '',
      street_address: '',
      province_ca: '',
      postal_code: '',
      email: '',
      phone_number: '',
      cat_or_dog: '',
      pet_s_name: '',
      pet_s_age: '',
      when_did_this_occur: '',
      heroic_pet_story_200_words_or_less: '',
      agree_box: false,
      agree_box_2: false,
      legal_text: '',
      success_message: '',
    },
  });
  const [wordsLeft, setWordsLeft] = useState(200);
  const watchedTextArea = watch('heroic_pet_story_200_words_or_less');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [apiError, setApiError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const apiRef = useRef<HTMLDivElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let formatted = formatPhoneNumber(event.target.value);
    if (formatted) {
      setPhoneNumber(formatted);
    }
  };

  useEffect(() => {
    if (watchedTextArea) {
      const wordCount = watchedTextArea.split(' ').length;
      setWordsLeft(200 - wordCount);
      if (wordCount > 200) {
        setError('heroic_pet_story_200_words_or_less', {
          type: 'manual',
          message: `${t('You have exceeded the word limit.')}`,
        });
      }
    }
  }, [watchedTextArea, setError, t]);

  const onSubmit: SubmitHandler<FormValues> = async formData => {
    const formattedData = {
      webform_id: 'nominate_a_life_saving_pet_hero',
      first_name: formData.first_name,
      last_name: formData.last_name,
      city: formData.city,
      street_address: formData.street_address,
      province_ca: formData.province_ca,
      postal_code: formData.postal_code,
      email: formData.email,
      phone_number: formData.phone_number,
      cat_or_dog: formData.cat_or_dog,
      pet_s_name: formData.pet_s_name,
      pet_s_age: formData.pet_s_age,
      when_did_this_occur: formData.when_did_this_occur,
      heroic_pet_story_200_words_or_less: formData.heroic_pet_story_200_words_or_less,
      agree_box: formData.agree_box,
      agree_box_2: formData.agree_box_2 || false,
    };

    setSubmitting(true);
    const nominatePetHeroResponse = await nominatePetHeroPost({ data: formattedData });

    if (!nominatePetHeroResponse) {
      setApiError(`${t('Something went wrong. Please try again.')}`);
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    if (nominatePetHeroResponse instanceof AxiosError) {
      setApiError(
        nominatePetHeroResponse.response?.data?.message ||
          `${t('Something went wrong. Please try again.')}`,
      );
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    setSubmitted(true);
    reset();
    setSubmitting(false);
  };

  if (submitting) {
    <Row className="justify-content-center">
      <Col className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{`${t('Submitting')}...`}</span>
        </Spinner>
        <span className="ms-3">{`${t('Please wait')}...`}</span>
      </Col>
    </Row>;
  }
  if (submitted) {
    return (
      <Row>
        <Col className="text-center">
          <Alert variant="success" className="my-3">
            <RichText
              body={
                getFieldDataByName('success_message')?.text ||
                `${t('Success! Thank you for submitting your life saving pet hero.')}`
              }
              className="d-block"
            />
          </Alert>
        </Col>
      </Row>
    );
  }
  return (
    <Container fluid className="mx-2 px-md-5 mx-lg-5 ">
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
        <Row className="gx-3 gy-3">
          {apiError && (
            <Alert variant="error" role="alert" tabIndex={0} ref={apiRef}>
              {apiError}
            </Alert>
          )}
        </Row>

        <Row className="gy-3 gx-2 py-2">
          <Col xs={12} md={6}>
            <Form.Group controlId="first_name" className="required">
              <Form.Label>{getFieldDataByName('first_name')?.title}</Form.Label>
              <Form.Control
                {...register('first_name', {
                  required: validateRequired(getFieldDataByName('first_name')?.title),
                  pattern: validateTextPattern(getFieldDataByName('first_name')?.title),
                })}
                type="text"
                isInvalid={!!errors?.first_name}
                aria-invalid={!!errors?.first_name}
                placeholder={getFieldDataByName('first_name')?.title}
                maxLength={60}
                aria-required="true"
              />
              {errors?.first_name && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.first_name.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="last_name" className="required">
              <Form.Label>{getFieldDataByName('last_name')?.title}</Form.Label>
              <Form.Control
                {...register('last_name', {
                  required: validateRequired(getFieldDataByName('last_name')?.title),
                  pattern: validateTextPattern(getFieldDataByName('last_name')?.title),
                })}
                type="text"
                isInvalid={!!errors?.last_name}
                aria-invalid={!!errors?.last_name}
                placeholder={getFieldDataByName('last_name')?.title}
                maxLength={60}
                aria-required="true"
              />

              {errors?.last_name && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.last_name.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        {/* ******************** CITY *********************** */}
        <Row className="gy-3 gx-2 py-2">
          <Col xs={12} md={6}>
            <Form.Group controlId="city" className="required">
              <Form.Label>{getFieldDataByName('city').title}</Form.Label>
              <Form.Control
                {...register('city', {
                  required: validateRequired(getFieldDataByName('city').title),
                  pattern: validateTextPattern(getFieldDataByName('city').title),
                })}
                type="text"
                isInvalid={!!errors?.city}
                aria-invalid={!!errors?.city}
                placeholder={getFieldDataByName('city').title}
                maxLength={60}
                aria-required="true"
              />
              {errors?.city && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.city.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="street_address" className="required">
              <Form.Label>{getFieldDataByName('street_address').title}</Form.Label>
              <Form.Control
                {...register('street_address', {
                  required: validateRequired(getFieldDataByName('street_address').title),
                  pattern: validateTextPattern(getFieldDataByName('street_address').title),
                })}
                type="text"
                isInvalid={!!errors?.street_address}
                aria-invalid={!!errors?.street_address}
                placeholder={getFieldDataByName('street_address').title}
                maxLength={60}
                aria-required="true"
              />
              {errors?.street_address && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.street_address.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        {/* ******************** PROVINCE *********************** */}

        <Row className="gy-3 gx-2 py-2">
          <Col xs={12} md={6}>
            <Form.Group controlId="province_ca" className="required">
              <Form.Label>{getFieldDataByName('province_ca')?.title}</Form.Label>
              <Form.Select
                {...register('province_ca', {
                  required: validateRequired(getFieldDataByName('province_ca').title),
                })}
                isInvalid={!!errors.province_ca}
                aria-invalid={!!errors.province_ca}
              >
                <option key="province-default" value="">
                  {t('Select')}
                </option>

                {provinces.map(([key, value]) => {
                  return (
                    <option value={key} key={key}>
                      {value}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="postal_code" className="required">
              <Form.Label>{getFieldDataByName('postal_code').title}</Form.Label>
              <Form.Control
                {...register('postal_code', {
                  required: 'Postal Code is required',
                  pattern: {
                    value: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
                    message: 'Invalid Canadian Postal Code',
                  },
                })}
                type="text"
                isInvalid={!!errors?.postal_code}
                aria-invalid={!!errors?.postal_code}
                placeholder={getFieldDataByName('postal_code').title}
                maxLength={60}
                aria-required="true"
              />
              <Form.Control.Feedback type="invalid">
                {errors.postal_code && errors.postal_code.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {/* ******************** EMAIL *********************** */}

        <Row className="gy-3 gx-2 py-2">
          <Col xs={12} md={6}>
            <Form.Group controlId="email" className="required">
              <Form.Label>{getFieldDataByName('email').title}</Form.Label>
              <Form.Control
                {...register('email', {
                  required: validateRequired(getFieldDataByName('email').title),
                  pattern: validateEmailPattern(),
                })}
                type="text"
                isInvalid={!!errors?.email}
                aria-invalid={!!errors?.email}
                placeholder={getFieldDataByName('email').title}
                maxLength={60}
                aria-required="true"
              />
              <Form.Control.Feedback type="invalid">
                {errors.email && errors.email.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          {/* ******************** PHONE *********************** */}

          <Col xs={12} md={6}>
            <Form.Group controlId="phone_number" className="required">
              <Form.Label>{getFieldDataByName('phone_number').title}</Form.Label>
              <Form.Control
                {...register('phone_number', {
                  required: 'Phone number is required',
                  pattern: {
                    value: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                    message: 'Phone number must be in format: xxx-xxx-xxxx',
                  },
                })}
                type="tel"
                value={phoneNumber}
                onChange={handleChange}
                isInvalid={!!errors?.phone_number}
                aria-invalid={!!errors?.phone_number}
                placeholder="ex. 555-123-4567"
                maxLength={60}
                aria-required="true"
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone_number && errors.phone_number.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {/* ******************** CAT OR DOG *********************** */}

        <Row className="gy-3 gx-2 py-2">
          <Col xs={6}>
            <Form.Group controlId="cat_or_dog" className="required">
              <Form.Label>{getFieldDataByName('cat_or_dog').title}</Form.Label>
              <Form.Select
                {...register('cat_or_dog', {
                  required: t('Please select either cat or dog.'),
                })}
                isInvalid={!!errors?.cat_or_dog}
                aria-invalid={!!errors?.cat_or_dog}
                aria-required="true"
              >
                <option value="">
                  {getFieldDataByName('cat_or_dog')?.emptyOption ?? t('Select')}
                </option>
                <option key="Cat" value="Cat">
                  {t('Cat')}
                </option>
                <option key="Dog" value="Dog">
                  {t('Dog')}
                </option>
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {errors.cat_or_dog && errors.cat_or_dog.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group controlId="pet_s_age" className="required">
              <Form.Label>{getFieldDataByName('pet_s_age').title}</Form.Label>
              <Form.Select
                {...register('pet_s_age', {
                  required: validateRequired(getFieldDataByName('pet_s_age').title),
                })}
                isInvalid={!!errors?.pet_s_age}
                aria-invalid={!!errors?.pet_s_age}
                aria-required="true"
              >
                <option value="">
                  {getFieldDataByName('pet_s_age')?.emptyOption ?? t('Select')}
                </option>
                {[...Array(18)].map((_, i) => (
                  <option key={i} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {errors.pet_s_age && errors.pet_s_age.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="gy-3 gx-2 py-2">
          <Col xs={12} md={6}>
            <Form.Group controlId="pet_s_name" className="required">
              <Form.Label>{getFieldDataByName('pet_s_name').title}</Form.Label>
              <Form.Control
                {...register('pet_s_name', {
                  required: validateRequired(getFieldDataByName('pet_s_name').title),
                  pattern: validateTextPattern(getFieldDataByName('pet_s_name')),
                })}
                type="text"
                isInvalid={!!errors?.pet_s_name}
                aria-invalid={!!errors?.pet_s_name}
                placeholder={getFieldDataByName('pet_s_name').title}
                maxLength={60}
                aria-required="true"
              />
              <Form.Control.Feedback type="invalid">
                {errors.pet_s_name && errors.pet_s_name.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          {/* ******************** When *********************** */}
          <Col xs={12} md={6}>
            <Form.Group controlId="when_did_this_occur" className="required">
              <Form.Label>{getFieldDataByName('when_did_this_occur').title}</Form.Label>
              <Form.Control
                {...register('when_did_this_occur', {
                  required: validateRequired(getFieldDataByName('when_did_this_occur').title),
                  pattern: validateWordLimit(getFieldDataByName('when_did_this_occur', 60).title),
                })}
                type="text"
                isInvalid={!!errors?.when_did_this_occur}
                aria-invalid={!!errors?.when_did_this_occur}
                placeholder={getFieldDataByName('when_did_this_occur').title}
                maxLength={60}
                aria-required="true"
              />
              <Form.Control.Feedback type="invalid">
                {errors.when_did_this_occur && errors.when_did_this_occur.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {/* ******************** STORY *********************** */}

        <Row className="gy-3 gx-2 py-2">
          <Col xs={12}>
            <Form.Group controlId="heroic_pet_story_200_words_or_less" className="required">
              <Form.Label>
                {getFieldDataByName('heroic_pet_story_200_words_or_less').title}
              </Form.Label>
              <Form.Control
                {...register('heroic_pet_story_200_words_or_less', {
                  required: validateRequired(
                    getFieldDataByName('heroic_pet_story_200_words_or_less').title,
                  ),
                  pattern: validateWordLimit(
                    getFieldDataByName('heroic_pet_story_200_words_or_less').title,
                  ),
                })}
                type="textarea"
                as="textarea"
                isInvalid={!!errors?.heroic_pet_story_200_words_or_less}
                aria-invalid={!!errors?.heroic_pet_story_200_words_or_less}
                maxLength={1350}
                aria-required="true"
                style={{ height: '8rem' }}
              />
              <p>
                {wordsLeft} / 200 {`${t('words')}`}
              </p>
              <Form.Control.Feedback type="invalid">
                {errors.heroic_pet_story_200_words_or_less &&
                  errors.heroic_pet_story_200_words_or_less.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {/* ******************** T & C *********************** */}
        <Row className="gy-3 gx-2 py-2">
          <Col xs={12} className="mt-3">
            {getFieldDataByName('disclaimer').description && (
              <RichText body={getFieldDataByName('disclaimer').description} />
            )}
            {/* <RecaptchaDisclaimer /> */}
          </Col>
          <Col>
            <Form.Group controlId="agree_box" className="required">
              <Form.Check
                typography-h2
                // eslint-disable-next-line max-lines
                type="checkbox"
                label={getFieldDataByName('agree_box').description}
              >
                <Form.Check.Input
                  type="checkbox"
                  aria-required="true"
                  {...register('agree_box', {
                    required: t('This Selection is required'),
                  })}
                />
                <Form.Check.Label>{getFieldDataByName('agree_box').description}</Form.Check.Label>
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.agree_box && errors.agree_box.message}
                </Form.Control.Feedback>
              </Form.Check>
            </Form.Group>
          </Col>
        </Row>
        <Row className="gy-3 gx-2 py-2">
          <Col xs={12}>
            <Form.Group controlId="agree_box_2">
              <Form.Check
                typography-h2
                type="checkbox"
                label={getFieldDataByName('agree_box_2').description}
              >
                <Form.Check.Input type="checkbox" {...register('agree_box_2')} defaultChecked />
                <Form.Check.Label>{getFieldDataByName('agree_box_2').description}</Form.Check.Label>
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.agree_box_2 && errors.agree_box_2.message}
                </Form.Control.Feedback>
              </Form.Check>
            </Form.Group>
          </Col>
        </Row>
        <Row className="gy-3 gx-2 py-4">
          {getFieldDataByName('legal_text')?.text && (
            <RichText className="fs-small" body={getFieldDataByName('legal_text')?.text} />
          )}
          <Col xs={12}>
            <Button
              variant="dark"
              className="rounded-pill justify-content-center  px-5 py-2"
              type="submit"
            >
              {submitting ? `${t('Please wait')}...` : getFieldDataByName('actions').submitLabel}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default NominatePetHeroForm;
