/* eslint-disable max-lines */
/* eslint-disable complexity */
import { PageProps, graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { styled } from '@linaria/react';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Layout from 'src/components/Layout';
import Seo from 'src/components/Seo';
import BazaarvoiceDCC from 'src/components/bazaarvoice/BazaarvoiceDCC';
import BazaarvoiceWidget from 'src/components/bazaarvoice/BazaarvoiceWidget';
import CompactCard from 'src/components/card/CompactCard';
import ProductCarousel from 'src/components/product/ProductCarousel';
import { getProductStatus } from 'src/components/product/helpers';
import { defaultColors } from 'src/theme/colors';
import { theme } from 'src/theme/theme';
import { getBrand, getProductVariationsList } from 'src/utils/helpers';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from 'src/utils/metatagHelpers';
import ProductTabs from './components/ProductTabs';
import ProductTitle from './components/ProductTitle';
import RelatedProducts from './components/RelatedProducts';

interface Image {
  gatsbyImage: IGatsbyImageData;
}
interface NodeProps extends NodeContent {
  created: Date;
  highlights: [string];
  image: ImageMetadata;
  image_gallery: ImageMetadata[];
  relationships: {
    products: [
      NodeContent & {
        field_bazaarvoice_id: string;
        image: ImageMetadata;
        relationships: {
          image: Image;
        };
      }
    ];
    status: TaxonomyTermStatus;
    brand: TaxonomyTerm;
    skus: ParagraphProductVariation[];
    category: [{ name: string; relationship: any }];
    image: Image;
    image_gallery: Image[];
    thumb_image: Image;
    thumb_image_gallery: Image[];
  };
  field_bazaarvoice_id: string;
  jwt_token: string;
  statusLink: LinkProps;
}
interface DataProps {
  node: NodeProps;
  relatedProducts: {
    edges: [{ node: NodeProps }];
  };
  translations: Translations;
}

const StyledProductContainer = styled(Container)`
  @media (min-width: ${theme.media.lg}) {
    background: linear-gradient(
      to right,
      #ecf0fa 0%,
      #ecf0fa 50%,
      #ecf0fa 50%,
      #ffffff 50%,
      #ffffff 100%
    );
    border-bottom: 1px solid ${defaultColors.border};
    border-top: 1px solid ${defaultColors.border};
  }

  img {
    cursor: pointer;
  }
`;

const StyledProductsRow = styled(Row)`
  list-style: none;
  padding-left: 0;

  li {
    list-style: none;
  }
`;

const ProductBundleTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { node, relatedProducts, translations },
  location,
}) {
  const { t } = useTranslation();
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);
  const brand = node.relationships?.brand ? getBrand(node.relationships.brand) : null;
  const products = node.relationships?.products;

  const productStatus = node.relationships.status
    ? getProductStatus(node.relationships.status, node.statusLink, node.created)
    : null;

  const skus = node.relationships?.skus;
  const sku: string = skus?.length && skus[0]?.upc ? skus[0].upc : '';
  // const sku: string = skus?.length && skus[0]?.upc ? formatSku(skus[0].upc) : '';
  const availableSizes = skus?.length >= 1 ? getProductVariationsList(skus, 'product_bundle') : [];

  let images = [];
  let thumbs = [];
  let imageMeta = [];

  if (node.relationships?.image) {
    images.push(node.relationships.image);
    imageMeta.push(node.image);
  }

  if (node.relationships?.thumb_image) {
    thumbs.push(node.relationships.thumb_image);
  }

  if (node.relationships?.image_gallery && node.relationships.image_gallery.length) {
    images = [...images, ...node.relationships.image_gallery];
    imageMeta = [...imageMeta, ...node.image_gallery];
  }

  if (node.relationships?.thumb_image_gallery && node.relationships.thumb_image_gallery.length) {
    thumbs = [...thumbs, ...node.relationships.thumb_image_gallery];
  }

  const bvID = node?.field_bazaarvoice_id
    ? `${node?.field_bazaarvoice_id.slice(0, 5)}-${node?.field_bazaarvoice_id.slice(5)}`
    : '';

  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      {bvID && <BazaarvoiceDCC token={node.jwt_token} />}
      <StyledProductContainer fluid className="p-0">
        <Container fluid="sm">
          <Row>
            <Col md={6} className="text-center mb-4 mb-md-0 bg-light px-0 py-2">
              <ProductCarousel
                productTitle={node.title}
                images={images}
                thumbs={thumbs}
                imageMeta={imageMeta}
              />
            </Col>
            <Col md={6} className="d-md-flex flex-md-column justify-content-md-center">
              <ProductTitle
                node={node}
                bvID={bvID}
                sku={sku}
                productStatus={productStatus}
                brand={brand}
                relatedProducts={relatedProducts}
              />
            </Col>
          </Row>
        </Container>
      </StyledProductContainer>
      <Container className="my-5">
        <h2 className="h3 fw-bold mb-5">{t('Products included in this pack')}</h2>
        {products?.length >= 1 && (
          <StyledProductsRow as="ul" className="row gy-4">
            {products?.map(product => {
              return (
                <Col lg={6} as="li" key={product.id}>
                  <CompactCard
                    href={product.path.alias}
                    media={
                      <GatsbyImage
                        objectFit="contain"
                        alt={product?.image?.alt}
                        image={product.relationships?.image?.gatsbyImage}
                      />
                    }
                  >
                    <div
                      className="d-flex flex-column justify-content-between"
                      style={{ height: '100%' }}
                    >
                      <div className="typography_h6" style={{ lineHeight: '1.4' }}>
                        {product.title}
                      </div>
                      <BazaarvoiceWidget
                        attributes={{
                          productID: product.field_bazaarvoice_id,
                          widgetID: 'inline_rating',
                        }}
                      />
                    </div>
                  </CompactCard>
                </Col>
              );
            })}
          </StyledProductsRow>
        )}
      </Container>
      <Container>
        <ProductTabs location={location} node={node} availableSizes={availableSizes} bvID={bvID} />
      </Container>
      {relatedProducts?.edges.length > 0 && <RelatedProducts relatedProducts={relatedProducts} />}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!, $brand_id: Int) {
    relatedProducts: allNodeProductBundle(
      limit: 20
      sort: { created: DESC }
      filter: {
        field_brand: { drupal_internal__target_id: { eq: $brand_id } }
        id: { ne: $id }
        langcode: { eq: $language }
        relationships: { field_product_status: { field_exclude: { ne: true } } }
      }
    ) {
      edges {
        node {
          title
          path {
            alias
          }
          default_langcode
          langcode
          field_bazaarvoice_id
          relationships {
            image: field_product_image {
              gatsbyImage(width: 400, height: 400, formats: AUTO, placeholder: NONE)
            }
            skus: field_product_variations {
              id
              upc: field_upc_code
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    translations: allNodeProductBundle(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    node: nodeProductBundle(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      title
      default_langcode
      langcode
      body {
        processed
        summary
      }
      created
      field_bazaarvoice_id
      highlights: field_highlights
      path {
        alias
      }
      ...Metatag
      image: field_product_image {
        alt
        height
        width
      }
      image_gallery: field_product_image_gallery {
        alt
        height
        width
      }
      statusLink: field_product_status_link {
        url
        title
      }
      jwt_token
      relationships {
        image: field_product_image {
          gatsbyImage(width: 1000, height: 1000, formats: AUTO, placeholder: NONE)
        }
        image_gallery: field_product_image_gallery {
          gatsbyImage(width: 1000, height: 1000, formats: AUTO, placeholder: NONE)
        }
        thumb_image: field_product_image {
          gatsbyImage(width: 100, height: 100, formats: AUTO, placeholder: NONE, layout: FIXED)
        }
        thumb_image_gallery: field_product_image_gallery {
          gatsbyImage(width: 100, height: 100, formats: AUTO, placeholder: NONE, layout: FIXED)
        }
        skus: field_product_variations {
          ...ParagraphProductVariation
        }
        brand: field_brand {
          name
          relationships {
            field_page_delegate {
              path {
                alias
              }
            }
            parent {
              name
              relationships {
                field_page_delegate {
                  path {
                    alias
                  }
                }
                parent {
                  name
                  relationships {
                    field_page_delegate {
                      path {
                        alias
                      }
                    }
                  }
                }
              }
            }
          }
        }
        products: field_product {
          ... on node__product {
            id
            title
            path {
              alias
            }
            field_bazaarvoice_id
            image: field_product_image {
              alt
              height
              width
            }
            relationships {
              image: field_product_image {
                gatsbyImage(width: 1000, height: 1000, formats: AUTO, placeholder: NONE)
              }
            }
          }
          ... on node__product_non_food {
            id
            title
            path {
              alias
            }
            field_bazaarvoice_id
            image: field_product_image {
              alt
              height
              width
            }
            relationships {
              image: field_product_image {
                gatsbyImage(width: 1000, height: 1000, formats: AUTO, placeholder: NONE)
              }
            }
          }
        }
        category: field_category {
          name
          relationships {
            parent {
              name
              relationships {
                parent {
                  name
                }
              }
            }
          }
        }
        status: field_product_status {
          name
          field_exclude
          variant: field_alert_type
        }
      }
    }
  }
`;

export default ProductBundleTemplate;
