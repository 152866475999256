import Loadable from '@loadable/component';
import { mdiArrowLeft, mdiMapMarker } from '@mdi/js';
import Icon from '@mdi/react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CMS_THEME } from '../common/enums';
import { ButtonLink as H20Button } from '../components/button/Button';
import { useMap } from '../components/ingredients/helpers/useMap';
import { defaultTheme } from '../theme/defaultTheme';
import { theme } from '../theme/theme';

const IngredientMap = Loadable(() => import('../components/ingredients/IngredientMap'));

interface Props {
  node: IStorageIngredientsMap;
}
interface IngredientProps {
  ingredient: TaxonomyTermIngredient;
  locations: TaxonomyTermLocation[];
  handleClose: () => void;
}

const DEFAULT_LOCATIONS: TaxonomyTermLocation[] = [];

const StyledImageWrapper = styled.div`
  max-width: 60px;
  width: 100%;
  border-radius: 999%;
`;

const StyledRowContainer = styled.div`
  border: 1px solid #e3e7f2;
  display: grid;
  grid-template-rows: 93px;
  grid-template-columns: 60px 1fr;
  align-content: center;
  gap: 10px;
  border-radius: 8px;
  padding-left: 1rem;
  align-items: center;
  width: 100%;
  background: #ffffff;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    background: #f5f5f5;
  }
`;

const StyledViewButton = styled(H20Button)`
  width: 100%;
  margin-bottom: 1rem;
  text-decoration: none;
`;

const StyledTitleDescriptionText = styled.h2`
  text-transform: 'capitalize';
  font-size: 32px;
  letter-spacing: -0.5px;
  margin: 8px 0 0 0;
`;

const classSpan = css`
  color: ${theme.light.action};
  font-size: 16px;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

const StyledBackButtonText = styled.h2`
  text-transform: 'uppercase';
  font-size: 1em;
  letter-spacing: 2.5px;
  vertical-align: middle;
  margin: 0;
`;

const classHr = css`
  border-color: ${defaultTheme.colors.border};
`;

const IngredientDescription = ({ ingredient, locations, handleClose }: IngredientProps) => {
  const { focusMapLocation } = useMap();
  const { t } = useTranslation();
  const image = ingredient.relationships?.field_image?.gatsbyImage;
  const benefits = ingredient.relationships?.field_benefits;
  const tIngredientAriaLabel = t('ingredient {{ingredientName}} detail', {
    ingredientName: ingredient.name,
  });

  return (
    <AnalyticsPoint
      type="component"
      action="shop by ingredient"
      typeLabel="ingredient_detail"
      label={tIngredientAriaLabel}
      as="div"
      className="vstack gap-3"
    >
      <div className="d-flex align-items-center gap-3">
        <Button
          variant="link"
          aria-label="Back to Ingredients"
          id="backButton"
          onClick={handleClose}
          className={cx('rounded-pill shadow p-2 ms-2 text-decoration-none justify-content-center')}
          style={{ height: '44px', width: '44px' }}
        >
          <Icon
            path={mdiArrowLeft}
            style={{ width: '100%', height: '100%' }}
            color="black"
            aria-hidden
          />
        </Button>
        <StyledBackButtonText className="text-uppercase fs-6">
          {t('back to ingredients')}
        </StyledBackButtonText>
      </div>
      <StyledTitleDescriptionText>{ingredient.name}</StyledTitleDescriptionText>
      {ingredient.description && (
        <Row className="py-3">
          <Col
            className="fs-5 fw-light"
            dangerouslySetInnerHTML={{ __html: ingredient.description?.processed }}
          />
        </Row>
      )}
      {image && (
        <>
          <Row>
            <Col xs={12} sm={12} className="p-0 rounded-3 border-1 border-dark">
              <GatsbyImage alt={ingredient.name} image={image} objectFit="cover" />
            </Col>
          </Row>
          {benefits && benefits.length >= 1 && (
            <Row>
              <h3 className="h4 mb-3">{t('Benefits')}</h3>
              <hr className={`border ${classHr}`} />
              {benefits.map(benefit => {
                const benefitIcon = benefit.relationships?.icon?.relationships?.svg?.url;
                return (
                  <React.Fragment key={benefit.id}>
                    <Row className="my-2 align-items-center">
                      {benefitIcon && (
                        <Col xs="auto" className="pe-0">
                          <img
                            src={benefitIcon}
                            alt={ingredient.name}
                            style={{ height: '24px', width: '24px' }}
                          />
                        </Col>
                      )}
                      <Col className="fs-5">{benefit.name}</Col>
                    </Row>
                    <hr className={`border mt-3 ${classHr}`} />
                  </React.Fragment>
                );
              })}
            </Row>
          )}
          <Row>
            <h3 className="h4">{t('Sourced from:')}</h3>
            <hr className={`border mt-3 ${classHr}`} />
            <Col className="d-flex flex-direction-row flex-wrap">
              <div>
                <Icon path={mdiMapMarker} size={2} color="black" />
              </div>
              <Col className="d-flex flex-direction-row flex-wrap">
                {locations.map((location, i) => {
                  return (
                    <div key={location.id}>
                      <Button
                        size="sm"
                        variant="link"
                        onClick={() => {
                          focusMapLocation(i);
                        }}
                        className={`fs-5 py-1 px-2 ${classSpan}`}
                      >
                        {location.name}
                      </Button>
                      <span>{i !== locations.length - 1 ? ' |' : ''}</span>
                    </div>
                  );
                })}
              </Col>
            </Col>
          </Row>
          {ingredient.is_primary && (
            <Row>
              <StyledViewButton
                to={`/search/products?f[0]=ingredients:${ingredient.drupal_internal__tid}`}
                variant="btn-outline"
                cmsTheme={CMS_THEME.LIGHT}
              >
                {t('Shop by {{ingredient.name}}', { ingredient })}
              </StyledViewButton>
            </Row>
          )}
        </>
      )}
    </AnalyticsPoint>
  );
};

interface IngredientItemProps {
  ingredient: TaxonomyTermIngredient;
  handleSelectNewIngredient: (id: string, locations: TaxonomyTermLocation[]) => void;
}

const IngredientItem = ({ ingredient, handleSelectNewIngredient }: IngredientItemProps) => {
  const image = ingredient.relationships?.field_image?.gatsbyImage;
  const newLocations = ingredient.relationships?.field_locations || [];
  const handleSelect = () => handleSelectNewIngredient(ingredient.id, newLocations);

  const handleKeyUp = (event: React.KeyboardEvent) => {
    const keyValue = event.key;
    if (keyValue === 'Enter' || keyValue === ' ') {
      event.preventDefault();

      handleSelect();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const keyValue = event.key;
    if (keyValue === 'Enter' || keyValue === ' ') {
      event.preventDefault();
    }
  };

  if (!image) {
    return null;
  }

  return (
    <React.Fragment key={ingredient.id}>
      <AnalyticsPoint
        action="select ingredient"
        type="component"
        node={{ ...ingredient, type: 'map_ingredient_choice' }}
      >
        <StyledRowContainer
          className="js-track"
          data-id={ingredient.drupal_id}
          onClick={handleSelect}
          key={ingredient.id}
        >
          <Col xs="auto" sm={2}>
            <StyledImageWrapper
              role="button"
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              tabIndex={0}
            >
              <GatsbyImage
                alt={ingredient.name}
                image={image}
                style={{ height: '55px', width: '55px' }}
              />
            </StyledImageWrapper>
          </Col>
          <Col xs="auto" sm={10}>
            <span className="fs-5 fw-light w-auto">{ingredient.name}</span>
          </Col>
        </StyledRowContainer>
      </AnalyticsPoint>
    </React.Fragment>
  );
};

const StorageIngredientsMap = function ({ node: ingredientMap }: Props) {
  const [selectedIngredientId, setSelectedIngredientId] = React.useState('');
  const breakpoint = useBreakpoint();
  const { updateMapMarkers, removeMarkers } = useMap();
  const [selectedLocations, setSelectedLocations] =
    React.useState<TaxonomyTermLocation[]>(DEFAULT_LOCATIONS);
  const ingredients = ingredientMap.relationships.field_ingredients;

  const findSelectedIngredient = (id: string) =>
    ingredients.find(
      (ingredient: TaxonomyTermIngredient) => ingredient.id === id,
    ) as TaxonomyTermIngredient;

  const selectedIngredient = findSelectedIngredient(selectedIngredientId);

  const isMobileBreakpoint = breakpoint.xs || (breakpoint.sm && !breakpoint.md);

  const handleSelectNewIngredient = (ingredientId: string, locations: TaxonomyTermLocation[]) => {
    setSelectedIngredientId(ingredientId);
    setSelectedLocations(locations);

    updateMapMarkers(locations);
  };

  const handleClose = () => {
    setSelectedIngredientId('');
    setSelectedLocations(DEFAULT_LOCATIONS);
    removeMarkers();
  };

  return (
    <AnalyticsPoint
      type="module"
      typeLabel="source_map"
      label="source map"
      as="div"
      data-id={ingredientMap.drupal_id}
      className="position-relative justify-center d-flex bg-white rounded-2"
      style={{ height: isMobileBreakpoint ? '850px' : '1130px' }}
    >
      <IngredientMap
        style={{ width: '100%', height: '100%', zIndex: 200 }}
        locations={selectedLocations as TaxonomyTermLocation[]}
      />

      <div
        className="p-4 w-auto w-xs-100 position-absolute rounded-3 overflow-auto"
        id="map-menu"
        style={{
          maxWidth: isMobileBreakpoint ? '360px' : '460px',
          top: isMobileBreakpoint ? '' : '28px',
          bottom: isMobileBreakpoint ? '28px' : '',
          left: isMobileBreakpoint ? '1rem' : '24px',
          right: '24px',
          height: isMobileBreakpoint ? '400px' : '1068px',
          backgroundColor: 'white',
          zIndex: 699,
        }}
      >
        <Col className="d-grid gap-3">
          {selectedIngredientId.length === 0 &&
            ingredients.map((ingredient: TaxonomyTermIngredient) => (
              <IngredientItem
                key={ingredient.id}
                ingredient={ingredient}
                handleSelectNewIngredient={handleSelectNewIngredient}
              />
            ))}
          {selectedIngredientId.length > 0 && (
            <IngredientDescription
              handleClose={handleClose}
              locations={selectedLocations as TaxonomyTermLocation[]}
              ingredient={selectedIngredient}
            />
          )}
        </Col>
      </div>
    </AnalyticsPoint>
  );
};

export default StorageIngredientsMap;
