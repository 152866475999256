import * as React from 'react';
import { Helmet, HelmetProps } from 'react-helmet';
import { JsonSchema } from 'src/utils/metatagHelpers';

interface SeoProps extends HelmetProps {
  schemaMarkup?: JsonSchema;
}

const getMeta = (
  meta: React.DetailedHTMLProps<React.MetaHTMLAttributes<HTMLMetaElement>, HTMLMetaElement>[],
  page: string
) =>
  meta.map(item => {
    if (item.name === 'title') {
      return { ...item, content: item.content?.replace('|', `page ${page} |`) };
    }

    return item;
  });

const getLink = (
  link: React.DetailedHTMLProps<React.LinkHTMLAttributes<HTMLLinkElement>, HTMLLinkElement>[],
  page: string
) => {
  return link.map(item => {
    if (item.rel === 'canonical') {
      return { ...item, href: `${item.href}?page=${page}` };
    }

    return item;
  });
};

const Seo: React.FC<SeoProps> = function ({ children, schemaMarkup, ...props }) {
  const { meta = [], link = [], htmlAttributes, title } = props;
  const isSSR = typeof window === 'undefined';
  const urlParams = new URLSearchParams(!isSSR ? window.location.search : '');
  const page = urlParams.get('page');
  const bv = urlParams.get('bvstate');
  const canon = page ?? bv ?? null;
  const seo = {
    meta: page ? getMeta(meta, page) : meta,
    link: canon ? getLink(link, canon) : link,
    title: page ? title?.replace('|', `page ${page} |`) : title,
  };

  return (
    <Helmet htmlAttributes={htmlAttributes} title={seo.title} link={seo.link} meta={[...seo.meta]}>
      {schemaMarkup && <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>}
      {children}
    </Helmet>
  );
};

export default Seo;
