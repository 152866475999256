import { mdiFacebook, mdiInstagram, mdiTwitter, mdiYoutube } from '@mdi/js';
import Icon from '@mdi/react';
import { navigate } from 'gatsby';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { styled } from '@linaria/react';
import { omit } from 'lodash';
import * as React from 'react';

const StyledList = styled.ul`
  list-style: none;
  li {
    margin-right: 0.25rem;
  }
  a {
    background: transparent;
    border: none;
    color: #ffffff;
    .icon {
      background-color: black;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 0.5);
      padding: 0.5rem;
      display: inline-flex;
      &:hover {
        background-color: #ffffff;
        color: black;
      }
    }
  }
`;

interface ParagraphProps extends Paragraph {
  links: [LinkProps];
}

interface ParagraphSocialLinksProps {
  node: ParagraphProps;
}

const ParagraphSocialLinks: React.FC<ParagraphSocialLinksProps> = function ({ node }) {
  const classes = node.behavior_settings?.decoupled_styles?.classes || [];

  const onSocialClick = (link: LinkProps, icon: string, e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const destination = link.url;
    const onCallback = () => {
      navigate(destination);
    };

    sendIt({
      event: 'follow_us',
      eventCategory: 'follow_us',
      eventAction: `clicked:${icon}`,
      eventLabel: '(not set)',
      eventCallback: onCallback,
      eventParams: {
        social_network: icon,
      },
    });
  };
  return (
    <StyledList className={classes.join(' ')}>
      {node.links.map((link, index) => {
        const icon = link.options?.attributes?.icon;
        if (icon) {
          let component = '';
          switch (icon) {
            case 'facebook':
              component = mdiFacebook;
              break;
            case 'twitter':
              component = mdiTwitter;
              break;
            case 'youtube':
              component = mdiYoutube;
              break;
            case 'instagram':
              component = mdiInstagram;
              break;
            default:
              break;
          }
          return (
            <li key={index}>
              <a
                href={link.url}
                onClick={e => onSocialClick(link, icon, e)}
                {...omit(link.options?.attributes, ['ariaLabel'])}
                aria-label={link.options?.attributes?.ariaLabel}
              >
                <span className="icon rounded-circle">
                  <Icon path={component} size={1} />
                </span>
                <span className="visually-hidden-focusable">{link.title}</span>
              </a>
            </li>
          );
        }
        return null;
      })}
    </StyledList>
  );
};

export default ParagraphSocialLinks;
