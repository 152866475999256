import React, { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { IProClubMemberFormData, ProClubMemberFormProps } from './types';

const TellUsMore = ({ getFieldDataByName }: ProClubMemberFormProps) => {
  const {
    register,
    unregister,
    watch,
    formState: { errors },
  } = useFormContext<IProClubMemberFormData>();

  const howDidYouHearValue = watch('howDidYouHear');

  useEffect(() => {
    if (howDidYouHearValue === 'Other') {
      register('howDidYouHearOther');
    } else {
      unregister('howDidYouHearOther');
    }
    if (howDidYouHearValue === 'Show') {
      register('howDidYouHearShow');
    } else {
      unregister('howDidYouHearShow');
    }
  }, [register, unregister, howDidYouHearValue]);

  return (
    <>
      <Row className="mt-4 mb-2">
        <Col>
          <h5>{getFieldDataByName('step_3')?.text}</h5>
        </Col>
      </Row>
      <Row className="gx-3 gy-3">
        <Col md={6}>
          <Form.Group controlId="referralName">
            <Form.Label>{getFieldDataByName('referred_by')?.title}</Form.Label>
            <Form.Control
              {...register('referralName')}
              type="text"
              isInvalid={!!errors?.referralName}
              aria-invalid={!!errors?.referralName}
              maxLength={60}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="feedingPets">
            <Form.Label>{getFieldDataByName('num_pets_feeding')?.title}</Form.Label>
            <Form.Control
              {...register('feedingPets')}
              type="number"
              isInvalid={!!errors?.feedingPets}
              aria-invalid={!!errors?.feedingPets}
              maxLength={60}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="puppiesProduced">
            <Form.Label>{getFieldDataByName('num_pets_bred')?.title}</Form.Label>
            <Form.Control
              {...register('puppiesProduced')}
              type="number"
              isInvalid={!!errors?.puppiesProduced}
              aria-invalid={!!errors?.puppiesProduced}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="priorProducts">
            <Form.Label>{getFieldDataByName('feeding_brands')?.title}</Form.Label>
            <Form.Control
              {...register('priorProducts')}
              type="text"
              isInvalid={!!errors?.priorProducts}
              aria-invalid={!!errors?.priorProducts}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="foodShoppingPlace">
            <Form.Label>
              {getFieldDataByName('where_do_you_currently_buy_your_dog_cat_food_')?.title}
            </Form.Label>
            <Form.Control
              {...register('foodShoppingPlace')}
              type="text"
              isInvalid={!!errors?.foodShoppingPlace}
              aria-invalid={!!errors?.foodShoppingPlace}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="needHelp">
            <Form.Label>{getFieldDataByName('help_choosing')?.title}</Form.Label>
            <Form.Control
              {...register('needHelp')}
              as="textarea"
              rows={3}
              isInvalid={!!errors?.needHelp}
              aria-invalid={!!errors?.needHelp}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="largeOrders">
            <Form.Label>{getFieldDataByName('pro_plan_delivery')?.title}</Form.Label>
            <Form.Select {...register('largeOrders')}>
              <option value={undefined}></option>
              <option value={getFieldDataByName('pro_plan_delivery')?.options[0].value}>
                {getFieldDataByName('pro_plan_delivery')?.options[0].label}
              </option>
              <option value={getFieldDataByName('pro_plan_delivery')?.options[1].value}>
                {getFieldDataByName('pro_plan_delivery')?.options[1].label}
              </option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="howDidYouHear">
            <Form.Label>{getFieldDataByName('how_did_you_hear')?.title}</Form.Label>
            <Form.Select {...register('howDidYouHear')}>
              <option value={undefined}></option>
              <option value={getFieldDataByName('how_did_you_hear')?.options[0].value}>
                {getFieldDataByName('how_did_you_hear')?.options[0].label}
              </option>
              <option value={getFieldDataByName('how_did_you_hear')?.options[1].value}>
                {getFieldDataByName('how_did_you_hear')?.options[1].label}
              </option>
              <option value={getFieldDataByName('how_did_you_hear')?.options[2].value}>
                {getFieldDataByName('how_did_you_hear')?.options[2].label}
              </option>
              <option value={getFieldDataByName('how_did_you_hear')?.options[3].value}>
                {getFieldDataByName('how_did_you_hear')?.options[3].label}
              </option>
              <option value={getFieldDataByName('how_did_you_hear')?.options[4].value}>
                {getFieldDataByName('how_did_you_hear')?.options[4].label}
              </option>
              <option value={getFieldDataByName('how_did_you_hear')?.options[5].value}>
                {getFieldDataByName('how_did_you_hear')?.options[5].label}
              </option>
              <option value={getFieldDataByName('how_did_you_hear')?.options[6].value}>
                {getFieldDataByName('how_did_you_hear')?.options[6].label}
              </option>
            </Form.Select>

            {howDidYouHearValue === 'Other' && (
              <>
                <Form.Label className="mt-3">
                  {getFieldDataByName('how_did_you_hear_other')?.title}
                </Form.Label>
                <Form.Control
                  {...register('howDidYouHearOther')}
                  type="text"
                  isInvalid={!!errors?.howDidYouHearOther}
                  aria-invalid={!!errors?.howDidYouHearOther}
                  maxLength={60}
                />
              </>
            )}
            {howDidYouHearValue === 'Show' && (
              <>
                <Form.Label className="mt-3">
                  {getFieldDataByName('how_did_you_hear_show')?.title}
                </Form.Label>
                <Form.Control
                  {...register('howDidYouHearShow')}
                  type="text"
                  isInvalid={!!errors?.howDidYouHearShow}
                  aria-invalid={!!errors?.howDidYouHearShow}
                  maxLength={60}
                />
              </>
            )}
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default TellUsMore;
