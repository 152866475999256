/* eslint-disable import/no-cycle */
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { css } from '@linaria/core';
import React, { useRef, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import {
  FieldError,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { contactUsPost } from '../../utils/clientRequests';
import { getRecaptchaToken } from '../../utils/formHelpers';
import Alert from '../alert/Alert';
import RichText from '../rich_text/RichText';
import Typography from '../typography/Typography';
import ConsentSection from './components/contactus/ConsentSection';
import ContactReason from './components/contactus/ContactReasonSection';
import PersonalInformationSection from './components/contactus/PersonalInformationSection';
import { ContactUsInputsType, contactUsSchema } from './utils/contactUs';

const submitButtonClass = css`
  min-width: 260px;
  margin-top: 2rem;
  @media (max-width: 540px) {
    width: 100%;
  }
`;

const rowClass = css`
  row-gap: 1rem;
`;

export interface IContactUsFormData extends ContactUsInputsType {
  recaptchaToken: string;
  agreed: boolean;
  langcode: Languages;
  phone_number: string; // this doesn't seem right.
}

interface IContactUsFormProps {
  getFieldDataByName: Function;
  submitCallback?: Function;
  langcode: Languages;
}

type CustomFieldError = Partial<{ type: string | number; message: string }> &
  Record<string, unknown>;

const ContactUsForm = ({ getFieldDataByName, submitCallback, langcode }: IContactUsFormProps) => {
  const { t } = useTranslation();
  const schema = contactUsSchema(t);
  const methods = useForm<IContactUsFormData>({
    shouldUnregister: true,
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [apiError, setApiError] = useState('');
  const apiRef = useRef<HTMLDivElement>(null);

  const { handleSubmit, reset } = methods;

  const onSubmit: SubmitHandler<IContactUsFormData> = async formData => {
    const formattedData = {
      webform_id: 'contact_us_form',
      agree_box: formData.emailSubscribingConsent,
      first_name: formData.firstName || '',
      last_name: formData.lastName || '',
      address_1: formData.address1 || '',
      address_2: formData.address2 || '',
      city: formData.city || '',
      province: formData.province,
      postal_code: formData.postal_code,
      email_address: formData.email,
      phone_number: formData.phone_number,
      number_of_dogs: formData.ownedDogs || 0,
      number_of_cats: formData.ownedCats || 0,
      upc_code_string: formData.upcCode || '',
      manufacturer_code: formData.manufactureCode || '',
      select_a_contact_reason: formData.contactReason,
      more_specifically_about: formData.moreSpecificReason,
      enter_your_message: formData.message || '',
      language: langcode || 'en',
      RecaptchaToken: '',
    };

    setSubmitting(true);
    formattedData.RecaptchaToken = await getRecaptchaToken();
    const contactUsResponse = await contactUsPost({ data: formattedData });

    if (!contactUsResponse) {
      setApiError('Something went wrong');
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    if (contactUsResponse instanceof AxiosError) {
      setApiError(contactUsResponse.response?.data?.message || 'Something went wrong');
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    if (navigator.onLine) {
      const submitAnalyticsForm = {
        event: 'contact_us_submit',
        eventCategory: 'contact us',
        eventAction: 'successful submission',
        eventLabel: '(not set)',
        eventParams: {
          contact_reason: formData.contactReason,
          contact_about: formData.moreSpecificReason,
          form_id: 'contact us',
          form_name: 'contact us',
          form_type: 'contact us',
          site_id: 'purina.ca',
        },
      };
      sendIt(submitAnalyticsForm);
    }

    if (submitCallback) {
      submitCallback();
    }

    setSubmitted(true);
    reset();
    setSubmitting(false);
  };

  const onError: SubmitErrorHandler<IContactUsFormData> = validationErrors => {
    const errorsArray = Object.entries(validationErrors);
    const sendErrorToGA = ([key, error]: [string, FieldError | CustomFieldError]) => {
      const { message, type } = error as FieldError;

      // client side error validations consists on
      // the key (field name), the type of error and the message
      const submitAnalyticsErrorForm = {
        event: 'contact_us_submit_error',
        eventCategory: 'contact_us_submit_error',
        eventAction: key,
        eventLabel: key,
        eventParams: {
          form_id: 'contact us',
          form_name: 'contact us',
          form_type: 'contact us',
          site_id: 'purina.ca',
          error_code: type?.toString() ?? 'ERROR',
          error_name: key,
          module_name: key,
          error_feature: message as string,
        },
      };

      sendIt(submitAnalyticsErrorForm);
    };

    // should send each valid error to the analytics team
    errorsArray.forEach(sendErrorToGA);
  };

  if (submitting) {
    return (
      <Row className="justify-content-center">
        <Col className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{`${t('Submitting')}...`}</span>
          </Spinner>
          <span className="ms-3">{`${t('Please wait')}...`}</span>
        </Col>
      </Row>
    );
  }

  if (submitted) {
    return (
      <Row>
        <Col className="text-center">
          <Alert variant="success" className="my-3">
            <RichText body={getFieldDataByName('success_message')?.text} />
          </Alert>
        </Col>
      </Row>
    );
  }

  return (
    <FormProvider {...methods}>
      <Typography className="text-danger">*{t(' Required Field')}</Typography>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Row className={rowClass}>
          {apiError && (
            <Alert variant="error" role="alert" tabIndex={0} ref={apiRef}>
              {apiError}
            </Alert>
          )}
          <ContactReason getFieldDataByName={getFieldDataByName} />
          <PersonalInformationSection getFieldDataByName={getFieldDataByName} />
          <ConsentSection getFieldDataByName={getFieldDataByName} />
        </Row>
        <Button
          variant="dark"
          className={`rounded-pill justify-content-center ${submitButtonClass}`}
          type="submit"
        >
          {submitting ? 'Please wait...' : getFieldDataByName('actions').submitLabel}
        </Button>
      </Form>
    </FormProvider>
  );
};

export default ContactUsForm;
