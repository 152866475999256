import { styled } from '@linaria/react';
import React from 'react';
import { CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';
import Link from '../Link';

const themesWithouthVariants = ['light_red', 'light_blue', 'gold'];

const baseCss = {
  all: 'unset',
  cursor: 'pointer',
  padding: '0.88rem 2rem',
  textShadow: 'none',
  textAlign: 'center',
  transition: 'all 255ms cubic-bezier(0.29, 0.19, 0.405, 1)',
  display: 'inline-flex',
  boxSizing: 'border-box',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
};

enum Target {
  borderRadius = 'borderRadius',
  border = 'border',
  background = 'background',
  color = 'color',
  textDecoration = 'textDecoration',
}

interface Props {
  cmsTheme: CMS_THEME;
  hover?: boolean;
  variant?: 'btn' | 'btn-outline' | 'btn-link';
  shape?: 'pill' | 'square' | 'smallRadius';
  mode?: 'red'; //Used for theme variations such as "dark-red".
}

interface HelperProps extends Props {
  target: Target;
}

const buttonHelper = ({
  cmsTheme,
  variant = 'btn',
  target,
  hover,
  shape = 'pill',
  mode,
}: HelperProps) => {
  const currentTheme = theme[cmsTheme];
  const x = {
    [Target.background]: () => {
      const defaultText = currentTheme.text;
      const grayTheme = currentTheme.gray;

      if (variant === 'btn-outline' && !themesWithouthVariants.includes(cmsTheme)) {
        if (hover) return defaultText.withAlpha(0.3);
        return 'transparent';
      }

      if (variant === 'btn-link' && !themesWithouthVariants.includes(cmsTheme)) {
        return 'transparent';
      }

      if (shape === 'square') {
        if (hover) return cmsTheme === 'dark' ? grayTheme[500] : grayTheme[300];
        return currentTheme.background.paper;
      }

      if (mode) {
        if (hover) {
          return currentTheme.modes?.[mode]?.primary?.hoverBackgroundColor;
        }

        return currentTheme.modes?.[mode]?.primary?.backgroundColor || defaultText.default;
      }

      if (hover)
        return currentTheme.btn?.primary?.hoverBackgroundColor || defaultText.withAlpha(0.3);

      return currentTheme.btn?.primary?.backgroundColor || defaultText.default;
    },
    [Target.color]: () => {
      if (variant === 'btn-link' && !themesWithouthVariants.includes(cmsTheme)) {
        return currentTheme.action;
      }

      if (variant === 'btn-outline' && !themesWithouthVariants.includes(cmsTheme)) {
        return currentTheme.text.default;
      }

      if (shape === 'square') {
        return currentTheme.text.default;
      }

      if (hover) return currentTheme.btn?.primary?.hoverColor || currentTheme.text.default;

      return currentTheme.text.invert;
    },
    [Target.border]: () => {
      if (
        shape === 'square' ||
        (variant === 'btn-link' && !themesWithouthVariants.includes(cmsTheme))
      ) {
        return 'none';
      }
      if (variant === 'btn-outline' && !themesWithouthVariants.includes(cmsTheme)) {
        return `1px solid ${currentTheme.text.default}`;
      }

      return '1px solid transparent';
    },
    [Target.borderRadius]: () => {
      if ((currentTheme.shape as any)[shape]) {
        return (currentTheme.shape as any)[shape];
      }

      if (shape === 'square') {
        return currentTheme.shape.rounded1;
      }

      return currentTheme.shape.roundedPill;
    },
    [Target.textDecoration]: () => {
      if (variant === 'btn-link') {
        return 'underline';
      }

      return 'none';
    },
  };

  return x[target]();
};

export const Button = styled.button<Props>`
  ${baseCss}
  border-radius: ${({ cmsTheme, variant }) =>
    buttonHelper({ cmsTheme, variant, target: Target.borderRadius })};
  border: ${({ cmsTheme, variant }) => buttonHelper({ cmsTheme, variant, target: Target.border })};
  background: ${({ cmsTheme, shape, variant, mode }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.background, mode })};
  color: ${({ cmsTheme, shape, variant, mode }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.color, mode })};
  text-decoration: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.textDecoration })};

  &:hover {
    background: ${({ cmsTheme, shape, variant, mode }) =>
      buttonHelper({ cmsTheme, shape, variant, hover: true, target: Target.background, mode })};
    color: ${({ cmsTheme, shape, variant, mode }) =>
      buttonHelper({ cmsTheme, shape, variant, hover: true, target: Target.color, mode })};
  }

  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }

  @media (min-width: ${theme.media.sm}) {
    width: auto;
  }
`;

export const ButtonLink = styled(Link)<Props>`
  ${baseCss}
  border-radius: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.borderRadius })};
  border: ${({ cmsTheme, variant }) => buttonHelper({ cmsTheme, variant, target: Target.border })};
  background: ${({ cmsTheme, shape, variant, mode }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.background, mode })};
  color: ${({ cmsTheme, shape, variant, mode }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.color, mode })};
  text-decoration: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.textDecoration })};

  &:hover {
    background: ${({ cmsTheme, shape, variant, mode }) =>
      buttonHelper({ cmsTheme, shape, variant, hover: true, target: Target.background, mode })};
    color: ${({ cmsTheme, shape, variant, mode }) =>
      buttonHelper({ cmsTheme, shape, variant, hover: true, target: Target.color, mode })};
  }

  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }

  @media (min-width: ${theme.media.sm}) {
    width: auto;
  }
`;

interface ButtonDivProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  cmsTheme: CMS_THEME;
  hover?: boolean;
  variant?: 'btn' | 'btn-outline' | 'btn-link';
  shape?: 'pill' | 'square';
  [prop: string]: any;
}
export const ButtonDiv = styled((props: ButtonDivProps) => <div {...props} />)`
  ${baseCss}
  border-radius: ${({ cmsTheme, variant }) =>
    buttonHelper({ cmsTheme, variant, target: Target.borderRadius })};
  border: ${({ cmsTheme, variant }) => buttonHelper({ cmsTheme, variant, target: Target.border })};
  background: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.background })};
  color: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.color })};
  text-decoration: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.textDecoration })};

  &:hover {
    background: ${({ cmsTheme, shape, variant }) =>
      buttonHelper({ cmsTheme, shape, variant, hover: true, target: Target.background })};
    color: ${({ cmsTheme, shape, variant }) =>
      buttonHelper({ cmsTheme, shape, variant, hover: true, target: Target.color })};
  }

  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }

  @media (min-width: ${theme.media.sm}) {
    width: auto;
  }
`;
