import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { navigate } from 'gatsby';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { styled } from '@linaria/react';
import * as React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const StyledDropdown = styled(Dropdown)`
  font-size: 14px;

  .dropdown-toggle,
  .show > .dropdown-toggle {
    border: 1px solid #b6bed2;
    background-color: #f8f9fc !important;
    border-radius: unset;
    color: black !important;
    font-size: 14px;
    box-shadow: none !important;
    max-width: 100%;

    &::after {
      display: none;
    }

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .dropdown-menu {
    font-size: 14px;
  }

  .dropdown-item {
    white-space: normal;
    border-bottom: 1px solid #e9ecef;

    &:last-child {
      border: none;
    }

    &:active,
    &.active {
      background-color: initial;
      color: initial;
    }
  }
`;

interface Props {
  node: NodeContent;
  products: [{ node: NodeContent }];
}

const ProductSelectFormula: React.FC<Props> = function ({ node, products }) {
  const handleSelect = (eventKey: string | null) => {
    if (eventKey) {
      navigate(eventKey);
    }
  };

  return (
    <StyledDropdown onSelect={handleSelect} className="my-4 mw-100">
      <Dropdown.Toggle title={node.title}>
        <span>{node.title}</span>
        <Icon path={mdiChevronDown} size={1} className="icon" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {products.map((item, index) => {
          return (
            <AnalyticsPoint
              type="component"
              node={{
                ...item.node,
                type: item.node.internal?.type || '',
                name: item.node.title,
              }}
              as={Dropdown.Item}
              eventKey={item.node.path.alias}
              key={index}
              className="js-track"
            >
              {item.node.title}
            </AnalyticsPoint>
          );
        })}
      </Dropdown.Menu>
    </StyledDropdown>
  );
};

export default ProductSelectFormula;
