import Cookies from 'js-cookie';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useWebform } from 'src/hooks/useWebform';
import NewsletterSignUpForm from '../components/forms/NewsletterSignUpForm';
import NewsleterSignupModal from '../components/forms/components/newslettersignup/NewsleterSignupModal';

interface StorageNewsletterSignupFormProps {
  node?: IStorageNewsletterSignupForm;
}

const StorageNewsletterSignUpForm = ({ node: data }: StorageNewsletterSignupFormProps) => {
  const { getFieldDataByName } = useWebform(data?.relationships?.form);

  const newsletterSignUpCookie = Cookies.get('newsletterSignUp');

  const handleModalSubmit = () => {
    Cookies.set('newsletterSignUp', 'Submitted', { expires: 90 });
  };

  const heading = (
    <>
      <h2 className="text-left">{data?.title}</h2>

      <div className="text-left mb-5">{data?.description}</div>
    </>
  );

  if (!data?.modal) {
    return (
      <Container className="py-5">
        <Row className="justify-content-md-center">
          <Col md="8">
            {heading}

            <NewsletterSignUpForm getFieldDataByName={getFieldDataByName} />
          </Col>
        </Row>
      </Container>
    );
  }

  if (data?.modal && !newsletterSignUpCookie) {
    return (
      <NewsleterSignupModal modalDelay={data?.modalDelay}>
        {heading}

        <NewsletterSignUpForm
          getFieldDataByName={getFieldDataByName}
          submitCallback={handleModalSubmit}
          modal
        />
      </NewsleterSignupModal>
    );
  }

  return null;
};

export default StorageNewsletterSignUpForm;
