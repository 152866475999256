import { mdiInformation } from '@mdi/js';
import Icon from '@mdi/react';
import { css } from '@linaria/core';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip, { TooltipProps } from 'react-bootstrap/Tooltip';

const tooltipClass = css`
  --bs-tooltip-max-width: 400px;
`;

const PurinaTooltip: React.FC<TooltipProps> = ({ children, ...props }) => {
  const renderTooltip = (tooltipProps: TooltipProps) => (
    <Tooltip id="button-tooltip" className={tooltipClass} {...tooltipProps}>
      {children}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      {...props}
      overlay={renderTooltip}
    >
      <Icon size={1} path={mdiInformation} color="black" />
    </OverlayTrigger>
  );
};

export default PurinaTooltip;
