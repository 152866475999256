import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useForm } from 'react-hook-form';

const BASE_ROUND_FACTOR = 10;
const LB_TO_KG = 0.453592;
// const KG_TO_LB = 2.2046226218;
/**
 * Number to substract from BCS score to determine BCS Adjustment.
 */
const BCS_ADJUSTMENT_FACTOR = 5;
/**
 * Multiplier to determine BCS adjusted weight.
 */
const BCS_ADJUSTMENT_MULTIPLIER = 0.1;
/**
 * Multiplier to determine calories for Adult dogs.
 */
const DOG_ADULT_MULTIPLIER = 139;

/**
 * Multiplier to determine calories for Senior dogs.
 */
const DOG_SENIOR_MULTIPLIER = 121;

/**
 * Multiplier to determine calories for cats.
 */
const CAT_ADULT_MULTIPLIER = 56;
const CAT_MATURE_MULTIPLIER = 50;
const CAT_SENIOR_MULTIPLIER = 60;
const CAT_GERIATRIC_MULTIPLIER = 80;
/**
 * Factor to determine Metabolic Weight in Dogs.
 */
const DOG_MW_FACTOR = 0.67;

/**
 * Multiplier to determine calories when goal is to lose weight.
 */
const DOG_LOSE_WEIGHT_MULTIPLIER = 104;
const CAT_LOSE_WEIGHT_MULTIPLIER = 40;

/**
 * The BCS limit to determine if the "Lose weight" goal option visibility.
 *
 * Anything lower than this number will hide the option.
 */
const BCS_LOSE_WEIGHT_THRESHOLD = 5;
const REQUIRED = 'This field is required.';

/**
 * The BCS limit to determine if the "Maintain weight" goal option visibility.
 *
 * Anything greater than this number will hide the option.
 */
// const BCS_MAINTAIN_WEIGHT_THRESHOLD = 8;

const calculateCalories = (
  species: 'cat' | 'dog',
  ageGroup: string,
  weight: number,
  units: 'lb' | 'kg',
  bcs: number
) => {
  const calories: Calories = {
    maintain: null,
    lose: null,
  };

  const bcsAdjustment = bcs - BCS_ADJUSTMENT_FACTOR;
  const kgWeight = units === 'kg' ? weight : weight * LB_TO_KG;
  const bcsAdjustedWeight = kgWeight - bcsAdjustment * BCS_ADJUSTMENT_MULTIPLIER * kgWeight;

  let multiplier = 1;
  let metabolicWeight;

  switch (species) {
    case 'dog':
      multiplier = ageGroup === 'adult' ? DOG_ADULT_MULTIPLIER : DOG_SENIOR_MULTIPLIER;
      metabolicWeight = bcsAdjustedWeight ** DOG_MW_FACTOR;
      calories.maintain = metabolicWeight * multiplier;
      if (bcs > BCS_LOSE_WEIGHT_THRESHOLD) {
        calories.lose = metabolicWeight * DOG_LOSE_WEIGHT_MULTIPLIER;
      }
      break;
    case 'cat':
      switch (ageGroup) {
        case 'adult':
          multiplier = CAT_ADULT_MULTIPLIER;
          break;
        case 'mature':
          multiplier = CAT_MATURE_MULTIPLIER;
          break;
        case 'senior':
          multiplier = CAT_SENIOR_MULTIPLIER;
          break;
        case 'geriatric':
          multiplier = CAT_GERIATRIC_MULTIPLIER;
          break;
        default:
          multiplier = CAT_ADULT_MULTIPLIER;
          break;
      }
      calories.maintain = bcsAdjustedWeight * multiplier;
      if (bcs > BCS_LOSE_WEIGHT_THRESHOLD) {
        calories.lose = bcsAdjustedWeight * CAT_LOSE_WEIGHT_MULTIPLIER;
      }
      break;
    default:
      break;
  }
  return calories;
};

type BcsRange = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

interface FormValues {
  weight: number;
  units: 'lb' | 'kg';
  age: string;
  bcs: BcsRange;
}

interface Calories {
  lose: number | null;
  maintain: number | null;
}

interface FeedingCalculatorProps {
  storage: StorageFeedingCalculator;
  servingSize: ParagraphServingSize;
}

const FeedingCalculator: React.FC<FeedingCalculatorProps> = function ({ storage, servingSize }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const [calories, setCalories] = React.useState<Calories | undefined>(undefined);

  const serving: { name: string; pluralName: string } = {
    name: servingSize?.relationships?.serving?.name || 'serving',
    pluralName: servingSize?.relationships?.serving?.pluralName || 'servings',
  };
  let targetServingMaintain: number | undefined;
  let targetServingLose: number | undefined;
  if (calories?.maintain) {
    targetServingMaintain =
      Math.round((calories.maintain / servingSize.calories) * BASE_ROUND_FACTOR) /
      BASE_ROUND_FACTOR;
  }
  if (calories?.lose) {
    targetServingLose =
      Math.round((calories.lose / servingSize.calories) * BASE_ROUND_FACTOR) / BASE_ROUND_FACTOR;
  }
  interface AgeOption {
    value: string;
    label: string;
  }
  let ageOptions: AgeOption[] = [];
  switch (storage.species) {
    case 'dog':
      ageOptions = [
        { value: 'adult', label: 'Young, active adult dogs' },
        { value: 'medium', label: 'Inactive or neutered adult dogs' },
        { value: 'senior', label: 'Senior dogs' },
      ];
      break;
    case 'cat':
      ageOptions = [
        { value: 'adult', label: 'Young, active adult cats (1-6 years of age)' },
        {
          value: 'mature',
          label: 'Mature (7-11 years) or inactive, indoor, or neutered adult cats',
        },
        { value: 'senior', label: 'Senior cats (12-15 years)' },
        { value: 'geriatric', label: 'Geriatric cats (15+ years)' },
      ];
      break;
    default:
      ageOptions = [];
      break;
  }

  const media = storage.relationships?.media;

  const onSubmit = handleSubmit(data => {
    const caloriesCalc = calculateCalories(
      storage.species,
      data.age,
      data.weight,
      data.units,
      data.bcs
    );
    if (caloriesCalc) {
      const maintainResults = `maintain ${targetServingMaintain} ${
        targetServingMaintain === 1 ? serving.name : serving.pluralName
      } per day`;
      const loseResults = `lose ${targetServingLose} ${
        targetServingLose === 1 ? serving.name : serving.pluralName
      } per day`;
      sendIt({
        event: 'pdp_feeding_calculator',
        eventCategory: 'product details',
        eventAction: 'feeding calculator form',
        eventLabel: 'calculate results',
        eventParams: {
          form_name: 'pdp feeding calculator',
          form_type: 'feeding recommendation',
          pet_weight: String(data.weight),
          units: data.units,
          age_group: data.age,
          body_condition_score: String(data.bcs),
          feeding_results_weight: `${maintainResults} | ${targetServingLose ? loseResults : ''}`,
          feeding_results_kcals: `maintain ${caloriesCalc.maintain} | ${
            caloriesCalc.lose ? caloriesCalc.lose : ''
          }`,
        },
      });
      setCalories(caloriesCalc);
    }
  });

  return (
    <Row>
      <Col md={6}>
        <Form className="mb-3" onSubmit={onSubmit}>
          <Row className="gx-3 gy-3">
            <Col xs={8} md={9}>
              <Form.Group>
                <Form.Label>{storage.form_settings.weight_label}</Form.Label>
                <Form.Control
                  {...register('weight', {
                    required: REQUIRED,
                  })}
                  type="number"
                  isInvalid={!!errors?.weight}
                  aria-invalid={!!errors?.weight}
                />
                {errors?.weight && (
                  <Form.Control.Feedback type="invalid" className="visually-hidden" role="alert">
                    {errors.weight.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xs={4} md={3}>
              <Form.Group>
                <Form.Label>{storage.form_settings.units_label}</Form.Label>
                <Form.Select {...register('units')}>
                  <option value="lb">lbs</option>
                  <option value="kg">kgs</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>{storage.form_settings.age_label}</Form.Label>
                <Form.Select {...register('age')}>
                  {ageOptions.map(option => {
                    return (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>
                  {storage.form_settings.bcs_label}
                  {media?.relationships?.pdf.url && (
                    <a
                      href={media.relationships.pdf.url}
                      target="_blank"
                      className="ms-2"
                      aria-label={media.name}
                      title={media.name}
                      rel="noreferrer"
                    >
                      <Icon path={mdiInformationOutline} size={0.8} />
                    </a>
                  )}
                </Form.Label>
                <Form.Select {...register('bcs')} defaultValue={5}>
                  <option value={1}>1 - Severely underweight</option>
                  <option value={2}>2 - Very thin</option>
                  <option value={3}>3 - Thin</option>
                  <option value={4}>4 - Slightly Underweight</option>
                  <option value={5}>5 - Ideal Weight</option>
                  <option value={6}>6 - Slightly Overweight</option>
                  <option value={7}>7 - Markedly Overweight</option>
                  <option value={8}>8 - Obese</option>
                  <option value={9}>9 - Clinically Obese</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Button className="rounded-pill justify-content-center" type="submit">
                {storage.form_settings.button_label}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col md={6} className="mt-3 mt-sm-0">
        <h3 className="text-center h6" id="table-heading">
          Recommended Daily Feeding Amounts
        </h3>
        <small className="text-center d-block mb-3">
          Based on serving size of {servingSize.calories} kcal/{serving.name}
        </small>
        <Row className="bg-light text-center p-5">
          <Col>
            <p>Maintain Weight</p>
            <p className="h4">{targetServingMaintain || '--'}</p>
            {targetServingMaintain && (
              <p>{`${targetServingMaintain === 1 ? serving.name : serving.pluralName} per day`}</p>
            )}
            {calories?.maintain && (
              <>
                <p className="h4">{Math.round(calories.maintain).toLocaleString()}</p>
                <p>kcal per day</p>
              </>
            )}
          </Col>
          {calories?.lose && targetServingLose && (
            <Col className="border-start">
              <p>Lose Weight</p>
              <p className="h4">{targetServingLose}</p>
              <p>
                {targetServingLose &&
                  `${targetServingLose === 1 ? serving.name : serving.pluralName} per day`}
              </p>
              <p className="h4">{Math.round(calories.lose).toLocaleString()}</p>
              <p>kcal per day</p>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default FeedingCalculator;
