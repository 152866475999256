/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import 'bootstrap/dist/css/bootstrap.min.css';
import wrapWithProvider from 'src/wrap-with-provider';

export const wrapRootElement = wrapWithProvider;

export const shouldUpdateScroll = ({ prevRouterProps, routerProps: { location } }) => {
  const prevLocation = prevRouterProps?.location;
  if (location.search === '') {
    // Scroll to the top of the page for a new navigation (no search string).
    return [0, 0];
  }
  if (
    prevLocation &&
    prevLocation.pathname === location.pathname &&
    prevLocation.search !== location.search
  ) {
    // Maintain scroll position
    return false;
  }
  // Scroll to the top of the page.
  return [0, 0];
};

// export const onRouteUpdateDelayed = () => {
//   window.___emitter.emit('routeDelayed');
// };
